// TABS
// <div class="tab-container">
// 	<div class='tabs'>
// 		<div class="tab active" rel="tab1">Tab1</div>
// 		<div class="tab" rel="tab2">Tab2</div>
// 	</div>

// 	<div id="tab1" class="tab-content active">
// 		<div class="inner">
// 			content here
// 		</div>
// 	</div>

// 	<div id="tab2" class="tab-content">
// 		<div class="inner">
// 			content here
// 		</div>
// 	</div>
// </div>

// $('.tabs .tab').click(function() {
// 	var thisOne = $(this);
// 	thisOne.parents('.tab-container').find('.tab-content, .tabs .tab').removeClass('active');
// 	var activeTab = $(this).attr("rel");
// 	thisOne.parents('.tab-container').find('#'+activeTab).addClass('active');
// 	$(this).addClass('active');
// 	thisOne.parents('.tab-container').find('.tabs .tab[rel^='+activeTab+']').addClass('active');

// 	setTimeout(function(){
// 		Waypoint.refreshAll();
// 	}, 500);
// });

////hash tabs
//
////add hash to url
//$('ul.tabs li').click(function(event) {
//	var activeTab = $(this).attr("rel");
//	window.location.hash = 'tab-' + activeTab;
//});
//
//checks for new hash
//$(window).on('hashchange',function(){
//	var newHash = location.hash.slice(5);
//	$('.tab-container .tabs li').removeClass('active')
//	$('.tab-container .tab-content').removeClass('active')
//	$("#"+newHash).addClass('active');
//	$("ul.tabs li[rel='"+newHash+"']").addClass("active");
//});
//
////on load check for a hash
//var hashLoad = window.location.hash.slice(5);
//if(hashLoad){
//	$('.tab-container .tabs li').removeClass('active')
//	$('.tab-container .tab-content').removeClass('active')
//	$("#"+hashLoad).addClass('active');
//	$("ul.tabs li[rel='"+hashLoad+"']").addClass("active");
//}

.tab-container.tab-container{
	position: relative;

	@media (max-width: $md){
		padding-bottom: 0;
	}

	.tabs{
		padding: 0 1.5rem;
		text-align: center;
		margin: 0 0rem 3.6rem;
		width: calc(100% + 0rem + 0rem);
		max-width: calc(100% + 0rem + 0rem);
		display: flex;
		flex-wrap: nowrap;
		position: relative;
		justify-content: flex-start;
		border-bottom: 1px solid #bababa;

		@media (max-width: $md){
			justify-content: flex-start;
			overflow: auto;
		}

		// @media (max-width: 650px){
		// 	flex-direction: column;
		// }

		.tab{
			padding: .5rem 4.1rem;
			@include font-size(20);
			text-decoration: none!important;
			line-height: 1.5;
			text-align: center;
			text-decoration: none;
			cursor: pointer;
			transition: $time;
			font-weight: 300;
			margin: 0;
			color: $colour;
			position: relative;
			font-family: $alt-font;
			margin-bottom: -1px;
			display: flex;
			align-items: center;

			@media (max-width: $md){
				padding: .5rem 1.1rem;
			}

			&:first-of-type{
				border-radius: 1rem 0 0 0;
			}

			&:last-of-type{
				border-radius: 0 1rem 0 0;
			}

			&.active{
				color: $white;
				background-color: $red;
			}

			&:hover,
			&:focus-visible{
				
				&:not(.active){
					color: $white;
					background-color: darken($red, 7.5%);
				}
			}
			
			&.active{
				cursor: default;
			}
		}
	}

	// .tab-accordion-heading{
	// 	display: none;
	// 	color: $blue;
	// 	text-align: center;
	// 	@include font-size(15);
	// 	padding: 13px 20px 13px;
	// 	background-image: url(../images/acc-down.svg);
	// 	background-repeat: no-repeat;
	// 	background-position: 100% 50%;

	// 	&.active{
	// 		background-image: url(../images/acc-up.svg);
	// 	}


	// 	@media (min-width: $b3 + 1){
	// 		display: none;
	// 	}
	// }

	.tab-content{
		max-height: 0;
		overflow: hidden;

		&.active{
			max-height: 9999px;
		}

		.inner{
			padding: 0 0;
			width: 77rem;
			max-width: 100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 auto;
		}

		h4{
			@include line-height(25,32);
		}
	}
}