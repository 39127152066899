//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid'; /* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'pagination';
//@import 'breadcrumb';
@import 'social';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'fancybox';
@import 'tabs';

.waypoint{
	transition: .2s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(5.0rem);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
		transition: .5s .1s;
	}
}

//
//!!BLOCKS START
//

.side-tabs{
	position: absolute;
	top: 19.7rem;
	right: 0;
	width: 5.8rem;
	z-index: 4;

	@media (max-width: $md){
		top: 7.7rem;
		width: 4.2rem;
	}

	img{
		width: 100%;
	}
}

body section{

	.container{
		position: relative;
		z-index: 1;
	}

	*:last-child{
		margin-bottom: 0;
	}
}

a.play-over i,
a.play,
.shape{
	aspect-ratio: 1/1;
	border-radius: 50% 0 50% 50%;

	&.red{
		background-color: $red;
	}

	&.green{
		background-color: $green;
	}

	&.pink{
		background-color: $pink;
	}

	&.blue{
		background-color: $blue;
	}

	&.blue-dark{
		background-color: $blue-dark;
	}
	
	&.blue-darker{
		background-color: $blue-darker;
	}

	&.teal{
		background-color: $teal;
	}

	&.orange{
		background-color: $orange;
	}

	&.yellow{
		background-color: $yellow;
	}

	&.purple{
		background-color: $purple;
	}

	img{
		aspect-ratio: 1/1;
		object-fit: cover;
		object-position: 50% 50%;
	}
}

a.play-over i,
a.play{
	background-color: $red;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover,
	&:focus-visible{
		background-color: darken($red, 7.5%);
	}

	svg{
		aspect-ratio: 38.39/66.79;
		width: 38.39 / 117 * 100%;
		margin-left: 6.8%;
	}
}

a.play-over{
	position: relative;

	i{
		width: 7.5rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 4;
	}
}


.hero-section{
	overflow: visible!important;
	position: relative;

	&::after{
		content: '';
		position: absolute;
		aspect-ratio: 1/1;
		width: 142.3%;
		max-width: 142.3%;
		top: calc(100% - 22rem);
		left: 50%;
		transform: translateX(-50%);
		background-color: $grey-light;
		z-index: -1;
		border-radius: 50% 0 50% 50%;

		@media (max-width: $md){
			display: none;
		}
	}

	.container{
		max-width: 106.2rem;
	}

	.text-side{
		padding-top: 5.6rem;

		@media (max-width: $md){
			padding-top: 5.6rem;
			order: 2;
		}

		@media (min-width: $md + 1){
			flex: 0 0 40.6%;
			max-width: 40.6%;
			padding-right: 6rem;
		}
	}

	.image-side{
		position: relative;

		.shape{
			position: absolute;

			&.shape1{
				width: 99 / 630 * 100%;
				top: 37 / 724 * 100%;
				left: -17 / 630 * 100%;
				z-index: 1;
			}

			&.shape2{
				width: 164 / 630 * 100%;
				top: 103 / 724 * 100%;;
				left: -26 / 630 * 100%;
			}

			&.shape3{
				width: 300 / 630 * 100%;
				top: 432 / 724 * 100%;;
				left: -315 / 630 * 100%;
			}

			&.shape4{
				width: 168 / 630 * 100%;
				top: 596 / 724 * 100%;;
				left: 454 / 630 * 100%;
			}

			&.shape5{
				width: 106 / 630 * 100%;
				top: 693 / 724 * 100%;
				left: 556 / 630 * 100%;
			}
		}

		@media (min-width: $md + 1){
			flex: 0 0 59.4%;
			max-width: 59.4%;
		}
		
		img{
			border-radius: 50% 0 50% 50%;

			@media (min-width: $md + 1){
				width: calc(100% + ((100vw - 106.2rem + 3rem)/2));
				max-width: calc(100% + ((100vw - 106.2rem + 3rem)/2));
			}
		}
	}
}

.services-hero-section,
.news-events-hero-section,
.hero-4-section,
.hero-3-section,
.hero-2-section{
	overflow: visible!important;
	position: relative;

	&::after{
		content: '';
		position: absolute;
		aspect-ratio: 1/1;
		width: 106.3%;
		max-width: 106.3%;
		top: -2rem;
		left: 50%;
		transform: translateX(-50%);
		background-color: $grey-light;
		z-index: -1;
		border-radius: 50% 0 50% 50%;

		@media (max-width: $md){
			display: none;
		}
	}

	.container{
		max-width: 106.2rem;
	}

	.row{
		padding-top: 5.5rem;
		align-items: flex-start;
	}

	.text-side{
		padding-top: 1.2rem;

		@media (min-width: $md + 1){
			flex: 0 0 59.4%;
			max-width: 59.4%;
			padding-right: 5rem;

			.page-id-392 &{
				padding-right: 10.7rem;

				h2{
					padding-right: 2.5rem;
				}
			}

			.page-id-431 &{
				padding-right: 3rem;

				h2{
					padding-right: 10.5rem;
					@include line-height(30,36);

					em{
						font-weight: 400;
					}
				}
			}
		}

		@media (max-width: $md){
			padding-top: calc(16vw + 1rem);
			order: 2;
		}

		h2{
			@include line-height(30,35);
			margin-bottom: 1em;
		}

		* + h2{
			margin-top: -.2em;
			margin-bottom: .3em;
		}
	}

	p{
		margin-bottom: 1.5em;
	}

	.image-side{
		position: relative;

		.shape{
			position: absolute;

			&.shape1{
				width: 154 / 431 * 100%;
				top: 0;
				left: -6.5%;
				z-index: 1;

				.page-id-392 &{
					background-color: $yellow;
				}

				.page-id-431 &{
					display: none;
				}
			}

			&.shape2{
				width: 130 / 431 * 100%;
				left: 14.5%;
				bottom: -11%;
			}

			&.shape3{
				width: 62 / 431 * 100%;
				left: 32.9%;
				bottom: -19.1%;
			}
		}

		@media (min-width: $md + 1){
			flex: 0 0 40.6%;
			max-width: 40.6%;
		}
		
		img{
			border-radius: 50% 0 50% 50%;
			width: 100%;

			@media (min-width: $md + 1){
				width: calc(100% + 3rem);
				max-width: calc(100% + 3rem);
			}
		}
	}
}

.hero-2-section{

	&:has(+ .our-centres-section),
	&:has(+ .center-text-with-under-blocks-section){

		&:after{
			display: none;
		}
	}

}

.hero-3-section{

	&::after{
		display: none;
	}

	.text-side{

		h2{
			margin-bottom: .4em;
			
			@media (min-width: $md + 1){
				padding-right: 2.5rem;
			}
		}

		p{
			margin-bottom: .6em;
		}
	}

	.image-side{
		position: relative;

		.shape{

			&.shape1{
				width: 35.73086%;
				top: -4.1%;
				left: -9.4%;
				z-index: 1;
			}

			&.shape2{
				width: 35.86241%;
				left: auto;
				right: 2.8%;
				bottom: 1.8%;
			}

			&.shape3{
				width: 16.28515%;
				left: auto;
				right: 17.5%;
				bottom: -4.8%;
			}
		}
	}
}

.hero-4-section{

	&::after{
		display: none;
	}

	.row{
		padding-bottom: 20.2rem;

		@media (max-width: $md){
			padding-bottom: 4rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-right: 3rem;
		}

		h2{
			margin-bottom: .45em;
		}

		p{
			margin-bottom: .6em;
		}

		* + h4{
			margin-top: 2.35em;
		}

		h4{
			margin-bottom: .7em;

			@media (min-width: $md + 1){
				@include font-size(24);
			}
		}

		.under-link{
			position: relative;

			.link{
				background-color: $red;
				aspect-ratio: 1/1;
				border-radius: 50% 0 50% 50%;
				@include font-size(26.22);
				@include line-height(26.22,32.97);
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				color: $white;
				width: 14rem;
				padding: 1.5rem .5rem 2.7rem;
	
				&:hover,
				&:focus-visible{
					background-color: darken($red, 7.5%);
				}
			
				@media (min-width: $md + 1){
					position: absolute;
					left: calc(100% - 1rem);
					bottom: -2rem;
				}

				@media (max-width: $md){
					@include font-size(24);
				}
			}
		}
	}

	.image-side{
		position: relative;

		.shape{

			&.shape1{
				width: 35.86241%;
				top: auto;
				bottom: -10.1%;
				left: auto;
				right: -4.6%;
				z-index: 1;
			}

			&.shape2{
				width: 30.26241%;
				left: 14.4%;
				bottom: -11.2%;
			}

			&.shape3{
				width: 14.38515%;
				left: 33.2%;
				bottom: -19.1%;
			}
		}
	}
}

.news-events-hero-section{

	&::after{
		display: none;
	}

	.row{
		
		@media (min-width: $md + 1){
			padding-top: 0;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-top: 5.7rem;
			padding-right: 3rem;
			flex: 0 0 63.9%;
			max-width: 63.9%;
		}
	}

	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 calc(36.1% + ((100vw - 106.2rem)/2));
			max-width: calc(36.1% + ((100vw - 106.2rem)/2));
			margin-right: calc((-100vw - 106.2rem)/2);
		}

		img{
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
			
			@media (min-width: $md + 1){
				max-width: calc(100% + 1.5rem);
			}
		}

		.shape{

			&.shape1{
				width: 33.46241%;
				top: 9.6%;
				left: -20.3%;
				z-index: 1;
			}

			&.shape2{
				width: 20.2%;
				top: 39.3%;
				left: -25%;
				z-index: 2;
			}
		}
	}
}

.services-hero-section{

	&.green{

		.text-side{
			
			&:before{
				background-color: $green;
			}
		}

		.image-side{

			.shape{

				&.shape3{
					display: none;
				}

				&.shape2{

					a{
						color: $yellow;

						&:hover,
						&:focus-visible{
							color: darken($yellow, 7.5%);
						}
					}
				}
			}
		}

		.quote-side{
			
			svg{

				*{
					fill: $green;
				}
			}
		}
	}

	&.green-dark{

		.text-side{

			@media (min-width: $md + 1){
				padding-left: 3.3rem;
				padding-right: 0rem;
				padding-top: 2rem;
			}
			
			&:before{
				background-color: $green-dark;
			}
		}

		.image-side{

			.shape{

				&.shape1{
					width: 155 / 600 * 100%;
					left: 42.3%;
					top: -7.2%;
					background-color: $yellow;
				}

				&.shape2{
					background-color: $purple;

					a{
						color: $yellow;

						&:hover,
						&:focus-visible{
							color: darken($yellow, 7.5%);
						}
					}
				}

				&.shape3{
					display: none;
				}
			}
		}

		.quote-side{
			
			svg{

				*{
					fill: green-dark;
				}
			}
		}
	}

	&.pink{

		.text-side{
			
			&:before{
				background-color: $pink;
			}

			*{
				color: $white;
			}
		}

		.image-side{

			.shape{

				&.shape1{
					width: 230 / 600 * 100%;
					top: auto;
					left: -8.3%;
					bottom: -14.6%;
					background-color: $blue-dark;
				}

				&.shape2{
					width: 195 / 600 * 100%;
					right: -5.2%;
					bottom: -10%;
					background-color: $yellow;
				}

				&.shape3{
					background-color: $blue;
				}
			}
		}

		.quote-side{
			
			svg{

				*{
					fill: $pink;
				}
			}
		}
	}

	&.purple{

		.text-side{
			
			&:before{
				background-color: $purple;
			}

			*{
				color: $white;
			}
		}

		.image-side{

			.shape{

				&.shape1{
					background-color: $blue-dark;
				}

				&.shape2{
					width: 195 / 600 * 100%;
					right: -5.2%;
					bottom: -10%;
					background-color: $yellow;
				}

				&.shape3{
					background-color: $blue;
				}
			}
		}

		.quote-side{
			
			svg{

				*{
					fill: $purple;
				}
			}
		}
	}

	&.blue{

		.text-side{
			
			&:before{
				background-color: $blue;
			}

			*{
				color: $white;
			}
		}

		.image-side{

			.shape{

				&.shape1{
					background-color: $blue-dark;
				}

				&.shape2{
					width: 195 / 600 * 100%;
					right: -5.2%;
					bottom: -10%;
					background-color: $yellow;
				}

				&.shape3{
					background-color: $blue;
				}
			}
		}

		.quote-side{
			
			svg{

				*{
					fill: $blue;
				}
			}
		}
	}

	&.orange{

		.text-side{
			
			&:before{
				background-color: $orange;
			}

			*{
				color: white;
			}
		}

		.image-side{

			.shape{

				&.shape1{
					background-color: $blue-dark;
				}

				&.shape2{
					right: -5.2%;
					bottom: -10%;
					background-color: $yellow;
				}

				&.shape3{
					background-color: $blue;
				}
			}
		}

		.quote-side{
			
			svg{

				*{
					fill: $orange;
				}
			}
		}
	}

	&.yellow{

		.text-side{

			@media (min-width: $md + 1){
				padding-left: 2.2rem;
				padding-right: 0rem;
				padding-bottom: 2.4rem;

				h4{
					@include font-size(26);
				}
			}

			h4{
				@include line-height(26,31);
			}
			
			&:before{
				background-color: $yellow;
			}
		}

		.image-side{

			.shape{

				&.shape1{
					background-color: $pink;
					
					@media (min-width: $md + 1){
						width: 25.76667%;
						top: 5.9%;
						left: 0.9%;
					}
				}

				&.shape3{
					display: none;
				}

				&.shape2{

					a{
						color: $yellow;

						&:hover,
						&:focus-visible{
							color: darken($yellow, 7.5%);
						}
					}
				}
			}
		}

		.quote-side{
			
			svg{

				*{
					fill: $green;
				}
			}
		}
	}

	&:has(.shape1.text){

		@media (min-width: $md + 1){
			
			.text-side{
				padding-top: 9.7rem;
				padding-bottom: 13.4rem;
				padding-right: 4rem;
			}
		}

		.row{
			padding-bottom: 19.8rem;
		}
	}

	&:has(.shape1.image){

		@media (min-width: $md + 1){
			
			.text-side{
				padding-top: 8.7rem;
				padding-bottom: 18.4rem;
				padding-right: 3rem;
			}
		}

		.row{
			padding-bottom: 16.3rem;
		}
	}

	&:has(.quote-side.quote-side){

		.row{
			padding-bottom: 1.9rem;
		}
	}

	&:after{
		display: none;
	}

	.row{
		padding-top: 10.7rem;
		padding-bottom: 10.7rem;

		@media (max-width: $md){
			padding-top: 3rem;
			padding-bottom: calc(40vw + 2rem);
		}
	}

	.text-side{
		align-self: stretch;
		padding-top: 1rem;
		padding-bottom: 6.4rem;
		display: flex;
		align-items: center;

		@media (max-width: $md){
			padding-bottom: 1rem;
			margin-top: calc(24vw + 0rem);
		}

		@media (min-width: $md + 1){
			flex: 0 0 40.4%;
			max-width: 40.4%;
		}

		&:before{
			content: '';
			position: absolute;
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			top: -5.5rem;
			bottom: -4.8rem;
			left: -5.5rem;
			max-width: none;
			z-index: -1;

			@media (max-width: $md){
				top: 50%;
				transform: translateY(-50%);
				width: calc(100% + 5.5rem + 5.5rem);
				max-width: calc(100% + 5.5rem + 5.5rem);
			}

			@media (max-width: $md){
				
				@media (min-width: 550px + 1){
					top: -8%;
				}
			}
		}

		.text{

			*:last-child{
				margin-bottom: 0;
			}
		}

		h1{
			@include line-height(45,55);
		}

		h2{
			@include line-height(30,36);
		}
	}

	.image-side{

		@media (min-width: $md + 1){
			flex: 0 0 56.5%;
			max-width: 56.5%;
		}

		@media (max-width: $md){
			display: flex;
			flex-wrap: wrap;
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 148 / 600 * 100%;
				top: 7.6%;
				left: 1.5%;
				z-index: 1;

				&.text{
					top: auto;
					bottom: -16rem;
					left: -36.8%;
					width: 35.9rem;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 1rem 5rem 3.6rem;

					@media (max-width: $md){
						width: 15rem;
						position: relative;
						display: none;
					}

					h2{
						@include line-height(31,39);

						@media (min-width: $md + 1){
							@include font-size(31);
						}
					}

					*{
						color: $white;
					}
				}

				&.image{
					width: 36.1rem;
					border: .2rem solid $blue-dark;
					top: auto;
					bottom: -16.1rem;
					left: -37%;
					width: 35.9rem;

					@media (max-width: $md){
						width: 30%;
						left: 1.5rem;
						bottom: 0;
					}
				}
			}

			&.shape2{
				width: 148 / 600 * 100%;
				left: auto;
				right: 2.8%;
				bottom: -1.9%;

				&.social.social{
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					width: 19.5rem;
					right: -5.2%;
					bottom: -10%;
					padding: 1rem 1rem 2.3rem;
					z-index: 2;

					@media (max-width: $md){
						right: -.15rem;
					}

					+ .shape3{
						width: 13%;
						left: auto;
						right: 24%;
						bottom: -6.5%;

						@media (max-width: $md){
							display: none!important;
						}
					}
					
					a{
						font-weight: 300;
						margin: 1.1rem;
						@include font-size(50);

						*{
							font-weight: 300;
						}
					}
				}
			}

			&.shape3{
				width: 78 / 600 * 100%;
				left: auto;
				right: 24%;
				bottom: -6.5%;
			}
		}
	}

	.quote-side{
		padding-left: 14.3rem;
		position: relative;
		margin-top: 8.3rem;

		@media (max-width: $md){
			padding-left: 9rem;
			order: 6;
			margin-top: 38vw;
		}

		@media (max-width: $sm){
			margin-top: 45vw;
		}

		@media (min-width: $md + 1){
			flex: 0 0 56.5%;
			max-width: 56.5%;
			margin-left: 44.4%;
		}

		svg{
			width: 14.3rem;
			aspect-ratio: 143/97.82;
			position: absolute;
			top: .4rem;
			left: -1.3rem;
			z-index: 1;

			@media (max-width: $md){
				left: 0rem;
				width: 8rem;
			}
		}

		p{
			font-style: italic;
			font-weight: 500;
		}
	}
}

.news-events-hero-section + .news-events-section{
	margin-top: -13.5rem;

	@media (max-width: $md){
		margin-top: 2rem;
	}
}

.news-events-section{
	
	.row{
		padding-bottom: 5.3rem;
	}

	.featured-con{

		.search &{
			opacity: 0;
			visibility: hidden;
			pointer-events: none;

			@media (max-width: $md){
				display: none;
			}
		}

		.featured{
			width: 103.1rem;
			margin: 0 auto 6.2rem;
			background-color: $red;
			border-radius: 2.9rem;
			display: flex;
			flex-wrap: wrap;
			padding: 3.3rem 9.2rem 3.2rem;
			position: relative;

			@media (max-width: $md){
				padding: 3.3rem 2.2rem 3.2rem;
			}

			.shape{
				position: absolute;

				&.shape1{
					width: 9.7rem;
					bottom: -4.4rem;
					right: -5rem;
					z-index: 1;
				}
			}
		
			a{
				@include font-size(25);
				line-height: 1.2;
				margin-bottom: .7rem;
				text-decoration: none;
				color: $white;
				display: inline-block;
				margin-right: 6.2rem;

				&:nth-of-type(2){
					order: 3;
				}

				@media (max-width: $md){
					@include font-size(21);
				}

				&:last-of-type{
					margin-right: 0;
				}

				&:nth-of-type(3){
					order: 2;
					margin-right: 6.2rem;
				}

				&:hover,
				&:focus-visible{
					color: darken($white, 15%);
				}

				&.active{
					font-weight: 600;
					color: $white;
					pointer-events: none;
				}
			}
		}
	}

	.col-md-9{

		@media (min-width: $md + 1){
			flex: 0 0 83.4%;
			max-width: 83.4%;

			.search &{
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		@media (max-width: $md){
			order: 3;
		}
		
	}

	.col-md-3{

		.search &{
			display: none;
		}

		@media (min-width: $md + 1){
			flex: 0 0 16.6%;
			max-width: 16.6%;
		}

		@media (max-width: $md){
			order: 2;
			margin-bottom: 2rem;
		}

		p{
			margin-bottom: .35em;
			@include line-height(18,22);
		}

		a{
			display: block;
			@include line-height(18,22);
			text-decoration: none;
			margin-bottom: .35em;
		}
		
	}
}


.video-with-text-section{
	overflow: visible!important;

	.row{
		padding-top: 5.7rem;
		padding-bottom: 12.6rem;
		align-items: flex-start;
		
		@media (max-width: $md){
			padding-bottom: 3.7rem;
		}
	}

	.image-side{
		position: relative;

		img{
			width: 100%;
			border-radius: 2.7rem;
			position: relative;
			z-index: 2;
		}

		a.play{
			position: absolute;
			width: 117 / 600 * 100%;
			bottom: -51 / 316 * 100%;
			right: -25 / 600 * 100%;
			z-index: 3;

			@media (max-width: $md){
				right: .75rem;
			}
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 230 / 600 * 100%;
				bottom: -126 / 316 * 100%;
				right: -57 / 600 * 100%;
				z-index: 1;

				
				@media (max-width: $md){
					right: -1.5rem;
				}
			}
		}
	}

	.text-side{
		padding-top: 3rem;

		@media (max-width: $md){
			padding-top: calc(20.2vw + 1rem);
		}

		@media (min-width: $md + 1){
			padding-left: 1.2rem;
			padding-right: 8rem;
		}

		h2{
			@include line-height(30,40);
		}
	}
}

.center-text-section + .video-with-text-2-section{
	background-color: $blue-light;
	
	.row{
		padding-top: 2.1rem;
		padding-bottom: 2.1rem;
		
		@media (max-width: $md){
			padding-top: calc(14.2vw + 1rem);
		}
	}

	.image-side{

		.shape{
			background-color: $pink;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-bottom: 3.4rem;
		}
	}
}

.image-with-bio-section + .video-with-text-2-section{
	background-color: $blue-light;
	position: relative;

	&::after{
		content: '';
		position: absolute;
		aspect-ratio: 1/1;
		width: 142%;
		max-width: 142%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-color: $white;
		border-radius: 50% 0 50% 50%;

		@media (max-width: $md){
			aspect-ratio: 0;
			object-fit: cover;
			object-position: 50% 50%;
			height: 100%;
			border-radius: 50% 0 0 0;
		}
	}

	.row{
		padding-top: 13.2rem;
		padding-bottom: 2.2rem;
	}

	.image-side{

		.shape{
			background-color: $pink;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-bottom: 3.4rem;
		}
	}

}

.video-with-text-2-section{
	overflow: visible!important;

	.row{
		padding-top: 19rem;
		padding-bottom: 12.6rem;
		align-items: flex-start;
		align-items: center;

		@media (max-width: $md){
			padding-top: calc(14.2vw + 1rem);
			padding-bottom: 4rem;
		}
	}

	.image-side{
		position: relative;
		
		@media (min-width: $md + 1){
			margin-top: -.5rem;
		}

		img{
			width: 100%;
			border-radius: 2.7rem;
			position: relative;
			z-index: 2;
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 154 / 600 * 100%;
				top: -24.5%;
				left: 10%;
				z-index: 1;
			}
		}

		h4{
			text-align: center;
			color: $red;
			margin-top: .52em;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-left: 1.5rem;
			padding-right: 13rem;
		}

		h2{
			@include line-height(30,40);
		}

		p{
			margin-bottom: .65em;
		}

		h4{
			margin-bottom: .5em;
			@include line-height(25,32);
		}
	}
}

.video-with-text-3-section{
	overflow: visible!important;

	.container {
		max-width: 100rem;
	}

	.row{
		padding-top: 10.3rem;
		padding-bottom: 12.6rem;
		align-items: flex-start;

		@media (max-width: $md){
			padding-top: calc(16.8vw + 1rem);
			padding-bottom: 4rem;
		}
	}

	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 60%;
			max-width: 60%;
		}

		img{
			width: 100%;
			border-radius: 2.7rem;
			position: relative;
			z-index: 2;
		}

		a.play{
			position: absolute;
			width: 117 / 600 * 100%;
			bottom: -51 / 316 * 100%;
			right: -25 / 600 * 100%;
			z-index: 3;

			@media (max-width: $md){
				right: .75rem;
			}
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 230 / 600 * 100%;
				bottom: -126 / 316 * 100%;
				right: -57 / 600 * 100%;
				z-index: 1;

				@media (max-width: $md){
					right: -1.5rem;
				}
			}

			&.shape2{
				width: 300 / 600 * 100%;
				top: -32.5%;
				left: -19%;
				z-index: 1;
			}
		}
	}

	.text-side{
		padding-top: 5.8rem;

		@media (max-width: $md){
			padding-top: calc(20.3vw + 1rem);
		}

		@media (min-width: $md + 1){
			flex: 0 0 40%;
			max-width: 40%;
			padding-left: 1.2rem;
		}

		h2{
			@include line-height(30,40);
			margin-bottom: -.1em;
		}

		h1{
			padding-left: 3.5rem;

			@media (min-width: $md + 1){
				@include font-size(69.23);
			}
		}
	}
}

.block-slide-section{

	.container {
		max-width: 119.6rem;
	}

	.row{
		padding-top: 4.4rem;
		padding-bottom: 4.4rem;
	}

	.text-top{
		text-align: center;
		margin-bottom: 8.2rem;

		@media (max-width: $md){
			margin-bottom: 6rem;
		}
	}

	.block-slide{
		margin-bottom: 7.5rem;

		.swiper-wrapper-con{
			overflow: visible!important;
			position: relative;

			*{
				cursor: none;
			}

			.mouse{
				width: 7.5rem;
				height: 5.2rem;
				background-image: url(../images/mouse.svg);
				background-repeat: no-repeat;
				background-size: 100% auto;
				position: absolute;
				transform: translate(-50%, -50%) scale(1);
				pointer-events: none;
				z-index: 2;
				transition: all 0s 0s, transform $time 0s;
				top: -2000px;

				&.hide{
					opacity: 1;
					visibility: hidden;
				}

				&.hovered{
					transform: translate(-50%, -50%) scale(1.3);
				}
			}
		}

		.swiper-slide{

			&.red .inner{
				background-color: $red;

				&:has(a:hover, a:focus-visible){
					background-color: darken($red, 5%);
				}
			}
		
			&.green .inner{
				background-color: $green;

				&:has(a:hover, a:focus-visible){
					background-color: darken($green, 5%);
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6{
					color: $blue-dark;
				}
			}
		
			&.pink .inner{
				background-color: $pink;

				&:has(a:hover, a:focus-visible){
					background-color: darken($pink, 7.5%);
				}
			}
		
			&.blue .inner{
				background-color: $blue;

				&:has(a:hover, a:focus-visible){
					background-color: darken($blue, 7.5%);
				}
			}
		
			&.blue-dark .inner{
				background-color: $blue-dark;

				&:has(a:hover, a:focus-visible){
					background-color: darken($blue-dark, 7.5%);
				}
			}
			
			&.blue-darker .inner{
				background-color: $blue-darker;

				&:has(a:hover, a:focus-visible){
					background-color: darken($blue-darker, 7.5%);
				}
			}
		
			&.teal .inner{
				background-color: $teal;

				&:has(a:hover, a:focus-visible){
					background-color: darken($teal, 7.5%);
				}

				*{
					color: $blue-dark;
				}
			}
		
			&.orange .inner{
				background-color: $orange;

				&:has(a:hover, a:focus-visible){
					background-color: darken($orange, 7.5%);
				}
			}
		
			&.yellow .inner{
				background-color: $yellow;

				&:has(a:hover, a:focus-visible){
					background-color: darken($yellow, 7.5%);
				}
			}
		
			&.purple .inner{
				background-color: $purple;

				&:has(a:hover, a:focus-visible){
					background-color: darken($purple, 7.5%);
				}
			}

			.inner{
				aspect-ratio: 1/1;
				width: 100%;
				border-radius: 50% 0 50% 50%;
				display: flex;
				padding: 7.3rem 1.9rem 5.5rem 3.2rem;
				position: relative;
				transition: $time;

				@media (max-width: $md){
					padding: 11vw 1.9rem 11vw 3.2rem;
					display: flex;
					align-items: center;
				}

				@media (max-width: $sm){
					padding: 26vw 1.9rem 24vw 3.2rem;
				}

				a{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					border-radius: 50% 0 50% 50%;
				}
				
				*{
					color: $white;
				}

				p{
					@include line-height(18,22);
				}
			}
		}
	}
}

.join-us-section{
	padding-top: 9.4rem;
	padding-bottom: 9.4rem;

	@media (max-width: $md){
		padding-top: 4.4rem;
		padding-bottom: 4.4rem;
	}

	.container{
		background-color: $blue-darker;
		max-width: 97rem;
		border-radius: 5.9rem;

		@media (min-width: $md + 1){
			padding: 4.3rem 4.1rem 5.3rem 4.1rem;
		}

		@media (max-width: $md){
			border-radius: 3.2rem;
			padding: 2.3rem 1.5rem 2.3rem 1.5rem;
			max-width: calc(100% - 3rem);
		}

		*{
			color: $white;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 48%;
			max-width: 48%;
		}
	}

	.image-side{

		@media (min-width: $md + 1){
			flex: 0 0 52%;
			max-width: 52%;
		}

		img{
			margin-left: -21.8%;
			width: 137.8%;
			max-width: 137.8%;
			margin-top: -15.4%;
			margin-bottom: -21.1%;

			@media (max-width: $md){
				margin: 4rem 0 0;
				width: 100%;
			}
		}
	}

	.link-con{
		margin-top: 5.5rem;
	}
}

.link{
	display: inline-flex;
	align-items: center;
	@include font-size(35);
	text-decoration: none;
	font-weight: 600;

	@media (max-width: $md){
		@include font-size(26);
	}

	&:hover,
	&:focus-visible{
		
		i{
			background-color: darken($red, 7.5%);
			transform: translateY(5%);
		}

		svg{

			*{
				fill: darken($red, 7.5%);
			}
		}
	}

	i{
		content: '';
		width: 4.5rem;
		aspect-ratio: 1/1;
		border-radius: 50% 0 50% 50%;
		background-color: $red;
		margin-right: 1.2rem;
	}

	svg{
		width: 4.9rem;
		aspect-ratio: 49/42.21;
		margin-right: .8rem;
		margin-top: .6rem;

		*{
			fill: $red;
		}
	}
}

.latest-news-and-events-section{

	.row{
		padding-top: 2.8rem;
		padding-bottom: 6rem;
	}

	.title-con{
		margin-bottom: 2.6rem;
		text-align: center;
	}

	.n-item.n-item.n-item{

		@media (min-width: $md + 1){
			margin-bottom: 0;

			.img-con{
				margin-bottom: 1.3rem;
			}
			
			.date{
				margin-bottom: .5rem;
			}
		}
	}
}

.n-item{
	margin-bottom: 8.9rem;

	@media (max-width: $md){
		margin-bottom: 3rem;
	}

	.inner{
		position: relative;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			&:hover,
			&:focus-visible{
				
				~ h4{
					color: darken($red, 7.5%)!important;
				}
			}
		}
	
		.date{
			color: $red;
			text-transform: uppercase;
			margin-bottom: .8rem;
		}

		.img-con{
			width: 100%;
			border-radius: 3.1rem;
			aspect-ratio: 470/288;
			margin-bottom: 1.1rem;
		}

		h4{
			font-weight: 600;
			transition: $time;
			color: $blue-dark!important;
		}
	}
}

.follow-us-on-instagram-section{

	.row{
		padding-top: 2.8rem;
		padding-bottom: 2.5rem;
	}

	.title-con{
		margin-bottom: 2.5rem;
		text-align: center;

		h1{
			display: inline-flex;
			align-items: center;

			img{
				margin-right: 1rem;
				width: 6.6rem;
			}
		}
	}
}

.i-item{
	margin-bottom: 3.2rem;

	
	.inner{
		background-color: #f1ebe8;
		aspect-ratio: 1/1;
		border-radius: 1.5rem;
	}
}

.stay-connected-section{

	.container{
		max-width: 100rem;
	}

	.row{
		padding-top: 7rem;
		padding-bottom: 7rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 4rem;
		}
	}

	.text-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 40%;
			max-width: 40%;
		}

		@media (max-width: $md){
			margin-bottom: 2rem;
		}

		.text{
			display: flex;
			align-items: center;
			padding: 6.4rem 2.8rem 7.4rem 4.7rem;
			width: 100%;
		}

		*{
			color: $white;
		}

		h1{
			@include line-height(40,44);
		}

		.shape:not(.text){
			position: absolute;

			&.shape1{
				width: 38.4%;
				top: -5.9%;
				left: -9%;
				z-index: -1;
			}

			&.shape2{
				width: 25.4%;
				bottom: 1.1%;
				right: .7%;
			}
		}
	}

	.form-side{

		@media (min-width: $md + 1){
			flex: 0 0 60%;
			max-width: 60%;
			padding-bottom: 5rem;
		}
	}
}

.care-section{

	.row{
		padding-top: 2.3rem;
		padding-bottom: 3.5rem;
		align-items: center;
	}

	.care-side{

		@media (min-width: $md + 1){
			flex: 0 0 25.1%;
			max-width: 25.1%;
			position: relative;
			z-index: 3;
		}

		@media (max-width: $md){
			margin-bottom: 2rem;
		}

		.inner{
			border-radius: 1rem;
			border: 1px solid #e9e9e9;
		}
	}

	.slide-side{
		position: relative;
		overflow: hidden;

		// &::before{
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	bottom: 0;
		// 	right: 100%;
		// 	width: 100vw;
		// 	max-width: 100vw;
		// 	background-color: white;
		// 	z-index: 2;
		// }

		@media (min-width: $md + 1){
			flex: 0 0 74.8%;
			max-width: 74.8%;
			padding-left: 3.6rem;
			flex: 0 0 calc(74.8% + ((100vw - 120rem)/2));
			max-width: calc(74.8% + ((100vw - 120rem)/2));
			margin-right: calc((-100vw - 120rem)/2);
		}

		h5{
			padding: 0 1.5rem;
			margin-bottom: .6em;
		}

		.swiper + .swiper{
			margin-top: 2.9rem;
		}

		.swiper{

			.swiper-wrapper-con{
				overflow: visible!important;
			}

			.swiper-slide{

				.inner{
					display: flex;
					align-items: center;
					//justify-content: center;
				}
			}
		}
	}
}

.image-with-center-text-section + .center-text-section{
	background-color: $blue-light;

	.row{
		padding-top: 5.2rem;
		padding-bottom: 5.2rem;
	}
}

.video-with-text-2-section + .center-text-section{
	
	.container{
		max-width: 89rem;
	}
}

.out-of-hours-section + .center-text-section{

	.center-text-section .container {
		max-width: 92rem;
	}

	p + h4{
		margin-top: 1.3em;
	}

	h4{
		margin-bottom: .5em;
	}

	p{
		margin-bottom: 1.3em;

		&.large{

			@media (min-width: $md + 1){
				line-height: 1.25;
				margin-bottom: .4em;
			}
		}

		&:last-child{
			margin-bottom: 0;
		}
	}
}

.hero-3-section + .center-text-section{

	.row{
		padding-top: 9.2rem;
		padding-bottom: 6.1rem;
	}

	h1{
		@include line-height(44.9,59.86);
	}
} 


.center-text-with-under-blocks-section,
.center-text-section{

	.container{
		max-width: 100rem;
	}

	.row{
		padding-top: 3.6rem;
		padding-bottom: 3.6rem;
		text-align: center;
	}

	h1{
		margin-bottom: 14.8rem;
		position: relative;

		&:last-child{
			
			&:after{
				display: none;
			}
		}

		&::after{
			content: '';
			position: absolute;
			top: calc(100% + 2.5rem);
			height: 11rem;
			left: 50%;
			width: .1rem;
			transform: translateX(-50%);
			background-color: $red;
		}
	}

	h4{
		margin-bottom: .57em;
	}

	p{
		margin-bottom: .7em;
	}
}

.center-text-with-under-blocks-section{

	&:has(+ .center-text-with-under-blocks-section){
		background-color: $blue-light;
	}

	+ .center-text-with-under-blocks-section{
		background-color: $blue-light;
		position: relative;
		overflow: visible!important;

		&:after{
			content: '';
			position: absolute;
			left: 50%;
			bottom: calc(100% - 20.8rem);
			transform: translate(-50%);
			aspect-ratio: 1/1;
			background-color: $white;
			width: 141%;
			max-width: 141%;
			border-radius: 50%;

			@media (max-width: $md){
				border-radius: 0 0 50% 50%;
				aspect-ratio: 0;
				height: 200%;
			}
		}
	}

	.container{
		max-width: 106rem;

		@media (min-width: $md + 1){
			
			.col-12:not(.col-md-6){
				padding-left: 4rem;
				padding-right: 4rem;
				margin-bottom: 4.8rem;
			}
		}
	}

	.row{
		padding-top: 9.4rem;
		padding-bottom: 4.6rem;

		@media (max-width: $md){
			padding-top: 4.6rem;
			padding-bottom: 4.6rem;
		}
	}

	.col-12:not(.col-md-6){

		@media (max-width: $md){
			margin-bottom: 2rem;
		}

		&:has(h1:last-child){
			margin-bottom: 0;
		}

		h1{
			margin-bottom: 14.8rem;
	
			&:last-child{
				
				&:after{
					display: block;
				}
			}
	
			@media (min-width: $md + 1){
				margin-bottom: 14rem;
	
				&::after{
					top: calc(100% + 1.5rem);
				}
			}
		}
	}

	.col-md-6{

		@media (max-width: $md){
			margin-bottom: 2rem;
		}

		.inner{
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;

			.link-side{
				width: 28%;

				@media (max-width: $md){
					width: 14rem;
				}
			}

			.text-side{
				width: 72%;
				text-align: left;

				@media (min-width: $md + 1){
					padding-left: 3rem;
					padding-right: 6rem;

					&:has(h4:first-of-type){
						padding-right: .2rem;
					}
				}

				@media (max-width: $md){
					width: calc(100% - 14rem);
					padding-left: 2rem;
				}

				h3{
					@include line-height(27,30);
					margin-bottom: .35em;
				}

				h1{

					em{
						font-weight: 400;
					}
				}
			}
		}
	}

	p{

		&:has(+ ul){
			margin-bottom: .5em;
		}
	}

	ul{

		*:last-child{
			margin-bottom: 0;
		}

		li{
			margin-bottom: .43em;
			padding-left: .85em;
			

			&:before{
				left: 0em;
				top: .55em;
			}
		}
	}

	.side-link{
		position: relative;

		.link{
			background-color: $red;
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			@include font-size(24.43);
			@include line-height(24.43,26);
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			color: $white;
			width: 14rem;
			padding: .8rem 1.4rem 1.8rem;

			&:hover,
			&:focus-visible{
				background-color: darken($red, 7.5%);
			}

			@media (max-width: $md){
				@include font-size(22);
			}
		}
	}
}

.image-with-center-text-section{

	&:has(+ .center-text-section){
		background-color: $blue-light;
		position: relative;

		&:after{
			content: '';
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%);
			aspect-ratio: 1/1;
			background-color: $white;
			width: 141%;
			max-width: 141%;
			border-radius: 50%;

			@media (max-width: $md){
				border-radius: 0 0 50% 50%;
				aspect-ratio: 0;
				height: 100%;
			}
		}

		.container{
			z-index: 2;
		}
	}

	.row{
		padding-top: 1.7rem;
		padding-bottom: 25rem;

		@media (max-width: $md){
			padding-bottom: 12rem;
		}
	}

	.image-side{

		@media (min-width: $md + 1){
			flex: 0 0 41.6%;
			max-width: 41.6%;
			margin-top: .6rem;
		}

		img{
			width: 100%;
		}
	}

	.text-side{
		text-align: center;

		@media (min-width: $md + 1){
			flex: 0 0 58.4%;
			max-width: 58.4%;
			padding-left: 3.1rem;
			padding-right: 10.3rem;
		}
	}

	h1{
		margin-bottom: .09em;

		em{
			font-weight: 400;
		}

		@media (min-width: $md + 1){
			@include font-size(65);
		}
	}

	h2{
		@include line-height(30,35);
	}
}

.image-with-bio-section + .image-with-bio-section,
.video-with-text-2-section + .image-with-bio-section{
	background-color: $blue-light;
}

.image-with-bio-section + .image-with-bio-section{

	@media (min-width: $md + 1){
		
		.row{
			padding-top: 3.2rem;

		}
	}
}

.image-with-bio-section{

	&:has(+ .image-with-bio-section){
		
		.row{
			padding-bottom: 0;
		}
	}

	&.left{

		@media (min-width: $md + 1){
			
			.image-side{
				order: 2;
			}

			.text-side{
				padding-left: 11.5rem;
			}
		}
	}

	.row{
		padding-top: 3.5rem;
		padding-bottom: 2.7rem;
		align-items: flex-start;

		@media (max-width: $md){
			padding-top: calc(11.4vw + 1rem);
		}
	}

	&.right{

		@media (min-width: $md + 1){
			
			.image-side{
				margin-left: 8.3%;
			}

			.text-side{
				padding-left: 11.5rem;
				padding-right: 13.1rem;
			}
		}
	}
	

	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 33.3%;
			max-width: 33.3%;
			margin-top: 5.2rem;
		}

		@media (max-width: $md){
			margin-bottom: 2rem;
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 33%;
				top: -12.3%;
				right: -7.3%;

				@media (max-width: $md){
					right: 0;
				}
			}

			&.shape2{
				width: 18%;
				top: 9.3%;
				right: -18.3%;

				@media (max-width: $md){
					right: -1.3%;
				}
			}
		}

		img{
			border-radius: 50% 0 50% 50%;
			width: 100%;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 58.4%;
			max-width: 58.4%;
		}

		h2{
			margin-bottom: .2em;

			@media (min-width: $md + 1){
				@include font-size(35);
			}
		}

		p{
			margin-bottom: .7em;
		}

		h5{

			@media (min-width: $md + 1){
				@include font-size(22);
				@include line-height(22,30);
			}
		}
	}
}

.team-section{

	.row{
		padding-top: 3rem;
		justify-content: center;
	}

	

	@media (min-width: $md + 1){

		.container{
			max-width: 120rem;
		}

		.col-12{
			padding-right: 9rem;
			padding-left: 9rem;
		}

		.row{
			margin-right: -9rem;
			margin-left: -9rem;
		}
	}

	.col-md-4{
		margin-bottom: 5.7rem;

		.inner{
			text-align: center;
		}

		.img-con{
			aspect-ratio: 1/1;
			width: 100%;
			border-radius: 50% 0 50% 50%;
			background-color: $red;
			margin-bottom: 1.4rem;
		}

		h4{
			font-weight: 600;
			color: $red;
			margin-bottom: .45em;
		}

		p{
			@include font-size(15);
			text-transform: uppercase;
			font-weight: 600;
		}
	}
}

.become-a-trustee-section{
	padding-top: 2.8rem;
	padding-bottom: 9.4rem;

	.container{
		background-color: $blue-darker;
		max-width: 97rem;
		border-radius: 5.9rem;

		@media (min-width: $md + 1){
			padding: 3.4rem 3.2rem 3.2rem 3.2rem;
		}

		@media (max-width: $md){
			border-radius: 3.2rem;
			padding: 2.3rem 1.5rem 2.3rem 1.5rem;
			max-width: calc(100% - 3rem);
		}

		*{
			color: $white;
		}
	}

	.left-side,
	.right-side{
		position: relative;

		.shape{
			position: absolute;
		}

		h4{
			margin-bottom: .05em;
		}

		h1{
			color: $yellow;

			@media (min-width: $md + 1){
				@include font-size(65);
				@include line-height(65,70);
			}
		}

		p{
			margin-bottom: .66em;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		address,
		blockquote,
		pre,
		table,
		li{

			a{
				color: $yellow;

				&:hover,
				&:focus-visible{
					color: darken($yellow, 20%);
				}
			}
		}
	}

	.left-side{
		padding-bottom: 17rem;

		@media (max-width: $md){
			padding-bottom: calc(42vw + 1rem);
		}

		@media (min-width: $md + 1){
			flex: 0 0 40.8%;
			max-width: 40.8%;
		}

		.shape{

			&.shape1{
				width: 10.5%;
				bottom: 11.8rem;
				left: 5.3%;
				z-index: 1;
			}

			&.shape2{
				width: 25.9%;
				bottom: 4.9rem;
				left: 4.9%;
			}
		}
	}

	.right-side{

		@media (min-width: $md + 1){
			flex: 0 0 59.2%;
			max-width: 59.2%;
			padding-top: .5rem;
		}

		.shape{

			&.shape1{
				width: 13.1%;
				bottom: -5.1rem;
				right: 1.2%;
			}
		}
	}

	.link-con{
		margin-top: 5.9rem;
	}
}

.contact-hero-section{

	.container{
		max-width: 106rem;
	}

	.row{
		padding-top: 7.1rem;
		padding-bottom: 7.2rem;

		@media (max-width: $md){
			padding-top: 4.1rem;
			padding-bottom: 4.1rem;
		}
	}

	.text-con{
		margin-bottom: 9rem;

		@media (max-width: $md){
			margin-bottom: 5rem;
		}
	}

	.col-md-6{

		.inner{
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			align-items: center;

			@media (max-width: $md){
				margin-bottom: 2rem;
			}
		}

		.icon-con{
			width: 12rem;
			margin-right: 1.5rem;
		}

		.info-con{
			width: calc(100% - 12rem - 1.5rem);

			h2{
				@include line-height(32.3,37.83);

				@media (max-width: $md){
					@include font-size(18);
				}

				@media (min-width: $md + 1){
					@include font-size(32.3);
				}

				a{
					font-weight: 400;
					color: currentColor;

					&:hover,
					&:focus-visible{
						color: darken($blue-dark, 7.5%);
					}
				}
			}
		}
	}
}

.locations-section{

	.row{
		padding-top: 9.3rem;
		padding-bottom: 9.1rem;

		@media (max-width: $md){
			padding-top: 4.1rem;
			padding-bottom: 4.1rem;
		}
	}

	.col-md-4{

		@media (max-width: $md){
			margin-bottom: 5rem;

			br{
				display: block;
			}
		}

		.inner{
			position: relative;
			padding-left: 8.7rem;

			svg{
				position: absolute;
				top: .7rem;
				left: 0;
				width: 7rem;
			}
		}
	}
}

.out-of-hours-section{
	overflow: visible!important;
	position: relative;

	&::after{
		content: '';
		position: absolute;
		aspect-ratio: 1/1;
		width: 141.5%;
		max-width: 141.5%;
		top: .2rem;
		left: 50%;
		transform: translateX(-50%);
		background-color: $grey-light;
		z-index: -1;
		border-radius: 50% 0 50% 50%;
	}

	.container{
		max-width: 106rem;

		&:after{
			content: '';
			position: absolute;
			bottom: 2rem;
			left: 1.5rem;
			right: 1.5rem;
			height: 1px;
			background-color: #b4b4b4;
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 6.7%;
				top: 0;
				left: 9.6%;
				z-index: 3;
			}

			&.shape2{
				width: 11.5%;
				top: 1.4rem;
				left: 12.6%;
				z-index: 2;
			}
		}
	}

	.row{
		padding-top: 10.8rem;
		padding-bottom: 8.7rem;
		text-align: center
	}

	.text-con{
		margin-bottom: 3.3rem;
		position: relative;
		z-index: 4;
	}

	.col-md-6{

		@media (max-width: $md){
			margin-bottom: 4rem;
		}

		.icon-con{
			display: flex;
			justify-content: center;
			align-items: flex-end;
			margin-bottom: 2.8rem;

			img{
				margin: 0 auto;
			}
		}

		p{
			margin-bottom: -.2em;

			a{
				color: currentColor;

				&:hover,
				&:focus-visible{
					color: darken($blue-dark, 7.5%);
				}
			}
		}
	}
}

.app-section{
	overflow: visible!important;

	.container{
		max-width: 75rem;
	}

	.row{
		padding-top: 15.8rem;
		padding-bottom: 24.5rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: calc(26.2vw + 1rem);
			padding-bottom: 2rem;
		}
	}

	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 47.6%;
			max-width: 47.6%;
		}

		@media (max-width: $md){
			flex: 0 0 80%;
			max-width: 80%;
			margin-left: 20%;
		}

		img{
			position: relative;
			z-index: 3;
			width: 100%;

			@media (max-width: $md){
				
			}
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 72%;
				top: -16.9%;
				left: -38.4%;
				z-index: 1;
			}

			&.shape2{
				width: 28.1%;
				top: 8%;
				left: -44%;
				z-index: 2;
			}
		}
	}

	.text-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 52.4%;
			max-width: 52.4%;
			padding-left: 1.9rem;
			padding-bottom: 3.3rem;
		}

		.shape{
			position: absolute;

			@media (max-width: $md){
				display: none;
			}

			&.shape1{
				width: 76%;
				bottom: -52.1%;
				right: -53.4%;
				z-index: 1;
			}

			&.shape2{
				width: 39%;
				bottom: -69.9%;
				right: -22.4%;
				z-index: 2;
			}
		}

		h3{
			margin-bottom: .3em;
		}

	}

	.app-links{
		display: flex;
		flex-wrap: wrap;
		margin-top: 8.5rem;

		@media (max-width: $md){
			margin-top: 4rem;
		}

		.app-link{
			text-align: center;
			padding: 0 .3rem;
			margin-bottom: 8rem;

			@media (max-width: $md){
				margin-bottom: 4rem;
			}

			&:has(+ .app-link){
				margin-right: 8rem;
			}

			img{
				margin: 0 auto 1.3rem;
				width: 10.6rem;
			}
		}
	}
}

html:has(.projects-section),
html:has(.fundraise-section){

	@media (min-width: $md + 1){
		overflow: initial!important;

		body{
			overflow: initial!important;
		}
	}
}

.fundraise-section{
	overflow: initial!important;
	position: relative;
	padding-bottom: 40.5rem;

	@media (max-width: $md){
		padding-bottom: 15.5rem;
	}

	&::after{
		content: '';
		position: absolute;
		width: 100%;
		max-width: 100%;
		top: -29.6rem;
		left: 50%;
		height: calc(100% + 29.6rem - 2.3rem);
		transform: translateX(-50%);
		background-color: $grey-light;
		z-index: -1;
		border-radius: 50vw 0 50vw 50vw;

		@media (max-width: $md){
			top: -3rem;
			height: calc(100% + 3rem - 2.3rem);
		}
	}

	.nav-side{
		padding-top: 2.8rem;
	}

	.side-nav{
		position: sticky;
		top: 1.4rem;
		top: 2.8rem;

		&:after{
			content: '';
			position: absolute;
			left: 0;
			top: .5rem;
			bottom: .5rem;
			width: 1px;
			background-color: #bababa;
		}
		
		a{
			display: block;
			position: relative;
			text-decoration: none;
			display: inline-block;
			padding-left: 4.2rem;
			width: 100%;

			&:has(+ a){
				margin-bottom: 3rem;

				@media (max-width: $md){
					margin-bottom: 1.5rem;
				}
			}

			&:after{
				content: '';
				position: absolute;
				left: 0;
				top: .5rem;
				bottom: .5rem;
				width: .6rem;
			}

			span{
				@include font-size(25);
				@include line-height(25,30);
				color: $colour;
				opacity: .5;

				@media (max-width: $md){
					opacity: 1;
				}
			}

			&:hover{

				span{
					opacity: .75;
				}
			}
			
			@media (min-width: $md + 1){
				
				&.active{

					&:after{
						background-color: $red;
					}
				
					span{
						opacity: 1;
					}
				}
			}
		}
	}

	.container-fluid{
		padding-top: 2.9rem;
		padding-bottom: 8.6rem;

		@media (max-width: $md){
			padding-bottom: 2.9rem;
		}

		&:last-of-type{
			padding-bottom: 0;
		}

		.row{
			align-items: flex-start;
		}

		&#section1{

			@media (min-width: $md + 1){
				
				.text-side{
					padding-right: 5rem;
				}
			}
		}

		&#section2{

			@media (min-width: $md + 1){
				
				.text-side{
					padding-right: 6rem;
				}
			}
		}

		&#section4{

			@media (min-width: $md + 1){
				
				.text-top{
					margin-bottom: 5.6rem;
					padding-right: 4rem;

					p{
						padding-right: 2rem;
					}
				}

				.text-side{
					padding-right: 4.5rem;

					p{
						margin-bottom: .6em;

						&:last-child{
							margin-bottom: 0;
						}
					}
				}

				.image-side{
					margin-top: 1.7rem;

					.shape{

						&.shape1{
							top: -4.6%;
							left: -7.7%;
						}

						&.shape2{
							left: auto;
							right: 9.5%;
							bottom: -8.7%;
						}

						&.shape3{
							left: auto;
							right: 23.5%;
							bottom: -16.2%;
						}
					}
				}
			}
		}
	}

	.links-f{
		margin-top: 1.9rem;

		.link{
			background-color: $red;
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			@include font-size(15);
			@include line-height(15,15);
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			color: $white;
			width: 9rem;
			padding: 1.5rem 0.5rem;

			&:hover,
			&:focus-visible{
				background-color: darken($red, 7.5%);
			}
		}

		+ *{
			margin-top: 1rem;
		}
	}

	.text-top{
		margin-bottom: 3.9rem;

		@media (min-width: $md + 1){
			padding-right: 13rem;
		}

		@media (max-width: $md){
			order: 1;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 55.5%;
			max-width: 55.5%;
		}

		@media (max-width: $md){
			order: 3;
		}

		h2{
			color: $red;
			margin-bottom: .35em;
		}

		p{
			margin-bottom: .8em;
		}

		.what{
			position: relative;
			padding: 3rem 0 3rem 14.3rem;
			margin-top: 2.4rem;
			margin-bottom: 4.6rem;

			@media (min-width: $md + 1){
				width: calc(100% + 15rem);
				max-width: calc(100% + 15rem);
			}

			p{
				margin-bottom: 0;
			}

			i{
				aspect-ratio: 1/1;
				position: absolute;
				width: 10.9rem;
				background-color: $green;
				border-radius: 50% 0 50% 50%;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}

			h2{
				color: currentColor;
				margin-bottom: .2em;
			}
		}
	}

	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 44.5%;
			max-width: 44.5%;
			margin-top: .6rem;
		}

		@media (max-width: $md){
			order: 2;
			margin-top: calc(8vw + 1rem);
			margin-bottom: calc(4.3vw + 2rem);
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 32.93086%;
				top: -8.4%;
				left: 29.6%;
				z-index: 1;
			}

			&.shape2{
				width: 33.16241%;
				left: 0.1%;
				bottom: -4.6%;
			}

			&.shape3{
				width: 15.08515%;
				left: -8.1%;
				bottom: -2.3%;
			}
		}

		img{
			border-radius: 50% 0 50% 50%;
			width: 100%;
		}
	}

	.fb-item{

		&:has(+ .fb-item){
			margin-bottom: 5.9rem;

			@media (max-width: $md){
				margin-bottom: 2rem;
			}
		}

		@media (max-width: $md){
			order: 4;
		}

		.inner{
			display: flex;
			flex-wrap: wrap;

			.txt-side{

				@media (min-width: $md + 1){
					flex: 0 0 69%;
					max-width: 69%;
					padding-left: 3rem;
				}

				@media (max-width: $md){
					width: 100%;
				}
		
				h2{
					color: $red;
					margin-bottom: .55em;
				}
		
				p{
					margin-bottom: .6em;
				}

				.links-f {
					margin-top: 2.4rem;
				}
			}
		
			.img-side{

				@media (min-width: $md + 1){
					flex: 0 0 31%;
					max-width: 31%;
					margin-top: .6rem;
				}

				@media (max-width: $md){
					width: 100%;
					margin-bottom: 2rem;
				}

				img{
					border-radius: 50% 0 50% 50%;
					width: 100%;
				}
			}
		}
	}

	.next-step{
		margin-top: 6.2rem;

		@media (max-width: $md){
			order: 5;
		}

		.inner{
			background-color: $red;
			border-radius: 5.9rem;
			position: relative;
			padding: 1.2rem 2.7rem 3.2rem 3.4rem;
			
			.shape{
				position: absolute;
	
				&.shape1{
					width: 11.8%;
					top: -1.5rem;
					right: 11.9%;
					z-index: 1;
				}
	
				&.shape2{
					width: 11.4%;
					right: 3.4%;
					bottom: -5rem;
				}
	
				&.shape3{
					width: 4.5%;
					right: 11.7%;
					bottom: -6.4rem;
				}
			}

			.text{
				position: relative;
				z-index: 4;
			}
			

			*{
				color: $white;
			}

			h1{
				margin-bottom: .15em;

				@media (min-width: $md + 1){
					@include font-size(65);
				}
			}

			p{
				margin-bottom: 1.3rem;
			}

			h4{
				margin-bottom: 1.1rem;
			}

			a{

				&:hover,
				&:focus-visible{
					color: darken($white, 15%);
				}
			}
		}
	}
}

.our-centres-section + .image-with-text-section{
	background-color: $blue-light;

	&:after{
		background-color: $white;
		z-index: 0;
		top: 12.4rem;

		@media (min-width: $md + 1){
			width: 141.5%;
			max-width: 141.5%;
		}
	}

	.container{
		max-width: 106.4rem;
	}

	.image-side{

		@media (min-width: $md + 1){
			flex: 0 0 43.3%;
			max-width: 43.3%;
		}

		.shape{

			&.shape1{
				width: 130 / 460 * 100%;
				left: 14%;
				top: auto;
				bottom: -11%;
				z-index: 2;
				background-color: $green;
			}
	
			&.shape2{
				width: 62 / 460 * 100%;
				left: 31.2%;
				bottom: -19.1%;
				background-color: $blue;
			}
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 56.7%;
			max-width: 56.7%;
			padding-top: 6.9rem;
			padding-left: 5.5rem;
			padding-right: 3.5rem;
		}

		h2{
			@include line-height(30,36);
		}
	}
}

.image-with-text-section{
	overflow: visible!important;
	position: relative;

	&::after{
		content: '';
		position: absolute;
		aspect-ratio: 1/1;
		width: 109.5%;
		max-width: 109.5%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-color: $grey-light;
		z-index: -1;
		border-radius: 50% 0 50% 50%;

		@media (max-width: $md){
			display: none;
		}
	}

	.container {
		max-width: 112.4rem;
	}

	.row{
		padding-top: 8.2rem;
		padding-bottom: 8.2rem;
		align-items: flex-start;

		@media (max-width: $md){
			padding-top: calc(10.4vw + 2rem);
			padding-bottom: 4rem;
		}
	}
	
	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 41%;
			max-width: 41%;
			margin-top: .4rem;
		}

		@media (max-width: $md){
			margin-bottom: calc(16.3vw + 2rem);
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 33%;
				top: -14.3%;
				left: 6.6%;
			}

			&.shape2{
				width: 33.2%;
				bottom: -17%;
				right: 4.7%;
				z-index: 2;
			}
		}

		img{
			border-radius: 50% 0 50% 50%;
			position: relative;
			z-index: 1;
			width: 100%;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 59%;
			max-width: 59%;
			padding-right: 6rem;
		}

		h4{
			margin-bottom: .7em;

			@media (min-width: $md + 1){
				@include font-size(24);
			}
		}

		p{
			margin-bottom: .6em;

			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}

.services-hero-section.blue + .two-column-text-section{

	.col-12{

		@media (min-width: $md + 1){
			
			&:nth-of-type(2){
				padding-right: 4.7rem;
			}
		}
	}

	h4{
		margin-bottom: .55em;
	}

	ul{

		li.large{
			margin-bottom: .58em;
		}
	}
}

.services-hero-section + .two-column-text-section{
	
	.container{
		max-width: 106.6rem;
	}

	.row{
		padding-top: 1.3rem;
		padding-bottom: 7.8rem;

		@media (max-width: $md){
			padding-bottom: 4rem;
		}
	}

	.col-12{

		@media (min-width: $md + 1){
			
			&:nth-of-type(1){
				padding-right: 5rem;
			}

			&:nth-of-type(2){
				padding-left: 1.7rem;
			}
		}
	}

	h4{
		margin-bottom: .25em;
	}

	ul{
		margin-bottom: 0;

		li{
			padding-left: .95em;

			&:before{
				left: 0;
			}
		}
	}
}

.two-column-text-section{

	&:has(+ .research-papers-section),
	&:has(+ .research-paper-section),
	&:has(+ .care-rating-section){

		.row{
			position: relative;
			padding-bottom: 5.7rem;

			&:after{
				content: '';
				position: absolute;
				left: 2rem;
				right: 2rem;
				bottom: 0rem;
				height: 1px;
				background-color: #bababa;
			}
		}
	}

	.row{
		padding-top: 4.5rem;
		padding-bottom: 3rem;
	}

	h4{
		margin-bottom: .6em;

		@media (min-width: $md + 1){
			@include font-size(24);
			@include line-height(24,30);
		}
	}

	.col-12{

		@media (min-width: $md + 1){
			
			&:nth-of-type(1){
				padding-right: 6rem;
			}

			&:nth-of-type(2){
				padding-left: 7.8rem;
			}
		}

		@media (max-width: $md){
			
			&:nth-of-type(1){
				margin-bottom: 2.5rem;
			}
		}
	}

	.under-link{
		display: flex;
		justify-content: flex-end;
		position: relative;

		.link{
			background-color: $red;
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			@include font-size(26.22);
			@include line-height(26.22,32.97);
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			color: $white;
			width: 14rem;
			padding: 1.5rem .5rem 2.7rem;

			&:hover,
			&:focus-visible{
				background-color: darken($red, 7.5%);
			}

			@media (min-width: $md + 1){
				margin-right: .5rem;
			}

			@media (max-width: $md){
				@include font-size(24);
			}
		}
	}
}

.two-column-text-section + .tabs-section{

	.row{
		padding-top: 0rem;
	}
}

.tabs-section{

	.container{
		max-width: 106rem;
	}

	.row{
		padding-top: 3rem;
		padding-bottom: 20.8rem;

		@media (max-width: $md){
			padding-bottom: 3.8rem;
		}
	}
}

.simple-text-section{

	.container{
		max-width: 106.2rem;
	}

	.row{
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
}

.four-zero-four-section{

	.row{
		padding-top: 15rem;
		padding-bottom: 15rem;
		text-align: center;
		align-items: center;

		@media (min-width: $md + 1){
			min-height: calc(100vh - 19.7rem - 28.5rem - 23.9rem);
		}
	}
}

.videos-with-text-section{
	background-color: $blue-light;

	@media (min-width: $md + 1){
		
		+ .videos-with-text-section{

			.row{
				padding-top: 3rem;
				padding-bottom: 6.7rem;
			}
		}
	}

	&.left{

		@media (min-width: $md + 1){
			
			.image-side{
				order: 2;
			}
		}
	}

	.row{
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.play-over{
		margin-bottom: 3.5rem;
		display: inline-block;
		width: 100%;
		border-radius: 2.7rem;
	}

	img{
		border-radius: 2.7rem;
	}

	.text-side{

		@media (min-width: $md + 1){
			margin-top: -1.1rem;
		}

		*:last-child{
			margin-bottom: 0;
		}
	}

	h4{
		@include line-height(25,32);
		margin-bottom: .45em;

		@media (min-width: $md + 1){
			padding-right: 8rem;
		}
	}

	p{

		&.large{
			margin-bottom: .48em;
			
			@media (min-width: $md + 1){
				@include font-size(25);
				@include line-height(25,32);
			}
		}
	}
}

.videos-with-text-section + .related-section{
	background-color: $blue-light;
	position: relative;

	&::after{
		content: '';
		position: absolute;
		aspect-ratio: 1/1;
		width: 142%;
		max-width: 142%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-color: $white;
		border-radius: 50% 0 50% 50%;

		@media (max-width: $md){
			aspect-ratio: 0;
			object-fit: cover;
			object-position: 50% 50%;
			height: 100%;
			border-radius: 50% 0 0 0;
		}
	}
}

.image-with-text-section + .related-section{

	.row{
		padding-top: 5.5rem;
	}
}

.text-blocks-section + .related-section{

	.row{
		padding-top: 7.5rem;
	}
}

.related-section{
	
	.container{
		max-width: 100rem;
		position: relative;
		z-index: 2;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: .7rem;
	}

	.title-con{
		text-align: center;

		h1{
			margin-bottom: 14.6rem;
			position: relative;
			color: $blue-dark!important;
	
			&::after{
				content: '';
				position: absolute;
				top: calc(100% + 1.6rem);
				height: 11rem;
				left: 50%;
				width: .1rem;
				transform: translateX(-50%);
				background-color: $red;
			}
		}
	}
}

.our-centres-section{
	background-color: $blue-light;
	position: relative;

	&::after{
		content: '';
		position: absolute;
		aspect-ratio: 1/1;
		width: 142%;
		max-width: 142%;
		top: calc(0% - 126.8rem);
		left: 50%;
		transform: translateX(-50%);
		background-color: $white;
		border-radius: 50% 0 50% 50%;

		@media (max-width: $md){
			top: 0;
			height: 138vw;
			border-radius: 0 0 50% 50%;
		}

		@media (min-width: 1600px + 1){
			top: calc(0% - 131.8rem);
		}
	}

	.container{
		// max-width: 100rem;
		position: relative;
		z-index: 2;
	}

	.row{
		padding-top: 5.9rem;
		padding-bottom: .2rem;
	}

	.title-con{
		text-align: center;

		h1{
			margin-bottom: 13.2rem;
			position: relative;
	
			&::after{
				content: '';
				position: absolute;
				top: calc(100% + 1.6rem);
				height: 11rem;
				left: 50%;
				width: .1rem;
				transform: translateX(-50%);
				background-color: $red;
			}
		}
	}

	.col-6{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 3rem;
	}

	.col-12.col-md-3,
	.col-12.col-md-9{
		
		@media (min-width: $md + 1){
			margin-top: 18.6rem;
			padding-bottom: 1.3rem;
			

			&:last-of-type{
				border-bottom: none;
			}
		}
	}

	.col-12.col-md-3{

		@media (min-width: $md + 1){

			~ .col-12.col-md-3{
				border-top: 1px solid #b3b5b9;
				padding-top: 3.9rem;
			}

			img{
				margin: 0 auto 2rem;
			}
		}

		@media (max-width: $md){
			
			img{
				margin: 4rem auto 2rem;
			}
		}
	}

	.col-12.col-md-9{

		@media (min-width: $md + 1){

			&:first-of-type{
				border-top: 1px solid #b3b5b9;
			}

			~ .col-12.col-md-9{
				border-top: 1px solid #b3b5b9;
				padding-top: 3.9rem;
			}
		}
	}

	.col-12.col-md-9{

		@media (min-width: $md + 1){

			~ .col-12.col-md-3,
			~ .col-12.col-md-9{
				margin-top: 3.9rem;
			}
		}
	}

	.col-md-9{

		*:last-child{
			margin-bottom: 0;
		}

		> *{

			@media (min-width: $md + 1){
				padding-right: 11.5rem;
			}
		}

		h2{
			@include line-height(30,36);
			margin-bottom: .4em;
		}

		p{

			&.large{
				margin-bottom: .59em;
			}

			@media (min-width: $md + 1){
				padding-right: 10rem;
			}
		}

		.links{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
			margin-top: 6.3rem;
			padding-right: 0;
		}

		.link{
			display: flex;
			align-items: flex-start;
			padding: 0;
			border-radius: 0;
			border: none;
			color: $blue-dark!important;
			font-weight: 400;
			
			@media (min-width: $md + 1){
				width: 45%;
			}

			@media (max-width: $md){
				width: 100%;

				&:has(+ .link){
					margin-bottom: 2rem;
				}
			}

			@media (min-width: $md + 1){
				@include font-size(29.93);
				@include line-height(29.93,35);
			}
	
			&:hover,
			&:focus-visible{
				background-color: transparent;
				color: $blue-dark!important;
				
				&::before{
					background-color: darken($red, 7.5%);
				}
			}
	
			&::before{
				content: '';
				width: 4.6rem;
				min-width: 4.6rem;
				aspect-ratio: 1/1;
				border-radius: 50% 0 50% 50%;
				background-color: $red;
				margin-right: 1.4rem;

				@media (min-width: $md + 1){
					transform: translateY(-.1rem);
				}
			}
		}
	}
}

.services-hero-section.services-hero-section.services-hero-section{

	&.green{

		& ~ section:not(.care-section){

			&.quote-section{

				&:after{
					background-color: $green;
				}
			}

			.n-item .date,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				color: $green;
			}

			&.projects-section svg{

				*{
					fill: $green;
				}
			}

			&.projects-section .swiper-pagination-bullet:hover, 
			&.projects-section .swiper-pagination-bullet:focus-visible, 
			&.projects-section .swiper-pagination-bullet-active,
			.ic-item .icon-con,
			&.research-papers-section .image-side .inner,
			&.text-blocks-section .col-md-4 .text,
			.title-con h1:after,
			&.download-with-text-section,
			li:before,
			.over,
			.icon-con{
				background-color: $green;
			}

			&.two-column-text-section,
			&.icon-blocks-section{

				.row{

					&:after{
						background-color: $green;
					}
				}
			}

			.link-con{

				.link{
					background-color: $green;

					&:hover,
					&:focus-visible{
						background-color: darken($green, 7.5%);
					}
				}
			}
		}
	}

	&.green-dark{

		& ~ section:not(.care-section){

			&.quote-section{

				&:after{
					background-color: $green-dark;
				}
			}


			.n-item .date,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				color: $green-dark;
			}

			&.projects-section svg{

				*{
					fill: $green-dark;
				}
			}

			&.projects-section .swiper-pagination-bullet:hover, 
			&.projects-section .swiper-pagination-bullet:focus-visible, 
			&.projects-section .swiper-pagination-bullet-active,
			.ic-item .icon-con,
			&.research-papers-section .image-side .inner,
			&.text-blocks-section .col-md-4 .text,
			.title-con h1:after,
			&.download-with-text-section,
			li:before,
			.over,
			.icon-con{
				background-color: $green-dark;
			}

			&.two-column-text-section,
			&.icon-blocks-section{

				.row{

					&:after{
						background-color: $green-dark;
					}
				}
			}

			.link-con{

				.link{
					background-color: $green-dark;

					&:hover,
					&:focus-visible{
						background-color: darken($green-dark, 7.5%);
					}
				}
			}
		}
	}

	&.pink{

		& ~ section:not(.care-section){

			&.quote-section{

				&:after{
					background-color: $pink;
				}
			}

			.n-item .date,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				color: $pink;
			}

			&.projects-section svg{

				*{
					fill: $pink;
				}
			}

			&.projects-section .swiper-pagination-bullet:hover, 
			&.projects-section .swiper-pagination-bullet:focus-visible, 
			&.projects-section .swiper-pagination-bullet-active,
			.ic-item .icon-con,
			&.research-papers-section .image-side .inner,
			&.text-blocks-section .col-md-4 .text,
			.title-con h1:after,
			&.download-with-text-section,
			li:before,
			.over,
			.icon-con{
				background-color: $pink;
			}

			&.two-column-text-section,
			&.icon-blocks-section{

				.row{

					&:after{
						background-color: $pink;
					}
				}
			}

			.link-con{

				.link{
					background-color: $pink;

					&:hover,
					&:focus-visible{
						background-color: darken($pink, 7.5%);
					}
				}
			}
		}
	}

	&.purple{

		& ~ section:not(.care-section){

			&.quote-section{

				&:after{
					background-color: $purple;
				}
			}

			.n-item .date,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				color: $purple;
			}

			&.projects-section svg{

				*{
					fill: $purple;
				}
			}

			&.projects-section .swiper-pagination-bullet:hover, 
			&.projects-section .swiper-pagination-bullet:focus-visible, 
			&.projects-section .swiper-pagination-bullet-active,
			.ic-item .icon-con,
			&.research-papers-section .image-side .inner,
			&.text-blocks-section .col-md-4 .text,
			.title-con h1:after,
			&.download-with-text-section,
			li:before,
			.over,
			.icon-con{
				background-color: $purple;
			}

			&.two-column-text-section,
			&.icon-blocks-section{

				.row{

					&:after{
						background-color: $purple;
					}
				}
			}

			.link-con{

				.link{
					background-color: $purple;

					&:hover,
					&:focus-visible{
						background-color: darken($purple, 15%);
					}
				}
			}
		}
	}

	&.blue{

		& ~ section:not(.care-section){

			&.quote-section{

				&:after{
					background-color: $blue;
				}
			}

			.n-item .date,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				color: $blue;
			}

			&.projects-section svg{

				*{
					fill: $blue;
				}
			}

			&.projects-section .swiper-pagination-bullet:hover, 
			&.projects-section .swiper-pagination-bullet:focus-visible, 
			&.projects-section .swiper-pagination-bullet-active,
			.ic-item .icon-con,
			&.research-papers-section .image-side .inner,
			&.text-blocks-section .col-md-4 .text,
			.title-con h1:after,
			&.download-with-text-section,
			li:before,
			.over,
			.icon-con{
				background-color: $blue;
			}

			&.two-column-text-section,
			&.icon-blocks-section{

				.row{

					&:after{
						background-color: $blue;
					}
				}
			}

			.link-con{

				.link{
					background-color: $blue;

					&:hover,
					&:focus-visible{
						background-color: darken($blue, 7.5%);
					}
				}
			}
		}
	}

	&.orange{

		& ~ section:not(.care-section){

			&.quote-section{

				&:after{
					background-color: $orange;
				}
			}

			.n-item .date,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				color: $orange;
			}

			&.projects-section svg{

				*{
					fill: $orange;
				}
			}

			&.projects-section .swiper-pagination-bullet:hover, 
			&.projects-section .swiper-pagination-bullet:focus-visible, 
			&.projects-section .swiper-pagination-bullet-active,
			.ic-item .icon-con,
			&.research-papers-section .image-side .inner,
			&.text-blocks-section .col-md-4 .text,
			.title-con h1:after,
			&.download-with-text-section,
			li:before,
			.over,
			.icon-con{
				background-color: $orange;
			}

			&.two-column-text-section,
			&.icon-blocks-section{

				.row{

					&:after{
						background-color: $orange;
					}
				}

				p a{
					color: $orange;

					&:hover,
					&:focus-visible{
						color: darken($orange, 7.5%);
					}
				}
			}

			&.two-column-text-section{

				.row{

					&:after{
						background-color: $blue-dark;
					}
				}
			}

			.link-con{

				.link{
					background-color: $orange;
					color: $white;

					&:hover,
					&:focus-visible{
						background-color: darken($orange, 7.5%);
					}
				}
			}
		}
	}

	&.yellow{

		& ~ section:not(.care-section){

			&.quote-section{

				&:after{
					background-color: $yellow;
				}
			}

			.n-item .date,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				color: $orange;
			}

			&.projects-section svg{

				*{
					fill: $yellow;
				}
			}

			&.projects-section .swiper-pagination-bullet:hover, 
			&.projects-section .swiper-pagination-bullet:focus-visible, 
			&.projects-section .swiper-pagination-bullet-active,
			.ic-item .icon-con,
			&.research-papers-section .image-side .inner,
			&.text-blocks-section .col-md-4 .text,
			.title-con h1:after,
			&.download-with-text-section,
			.over,
			.icon-con{
				background-color: $yellow;
			}

			&.two-column-text-section,
			&.icon-blocks-section{

				.row{

					&:after{
						background-color: $yellow;
					}
				}

				p a{
					color: $yellow;

					&:hover,
					&:focus-visible{
						color: darken($yellow, 7.5%);
					}
				}
			}

			&.two-column-text-section{

				.row{

					&:after{
						background-color: $blue-dark;
					}
				}
			}

			.link-con{

				.link{
					background-color: $yellow;
					color: $white;

					&:hover,
					&:focus-visible{
						background-color: darken($yellow, 7.5%);
					}
				}
			}
		}
	}
}

.icon-blocks-section{

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 6.55rem;
			padding-left: 6.55rem;
		}

		.row{
			margin-right: -6.55rem;
			margin-left: -6.55rem;
		}
	}

	&:has(.col-12:nth-of-type(5)){

		@media (min-width: $md + 1){

			.container,
			.col-12{
				padding-right: 4.5rem;
				padding-left: 4.5rem;
			}
	
			.row{
				margin-right: -4.5rem;
				margin-left: -4.5rem;
			}
		}

		.col-12.col-12.col-12{

			
			@media (min-width: $sm + 1){
				flex: 0 0 50%;
				max-width: 50%;
			}
	
			@media (min-width: $md + 1){
				flex: 0 0 33.3333%;
				max-width: 33.3333%;
				margin-bottom: 8.65rem;
			}

			p{
				font-weight: 600;
			}
		}
	}

	.row{
		padding-top: 0rem;
		position: relative;
		justify-content: center;

		&:after{
			content: '';
			position: absolute;
			left: 1.5rem;
			right: 1.5rem;
			bottom: 0rem;
			height: 1px;
			background-color: #bababa;

			@media (min-width: $md + 1){
				left: 8.55rem;
				right: 8.55rem;
			}
		}
	}

	.col-12{
		flex: 0 0 100%;
		max-width: 100%;
		text-align: center;
		margin-bottom: 6.25rem;

		@media (min-width: $sm + 1){
			flex: 0 0 50%;
			max-width: 50%;
		}

		@media (min-width: $md + 1){
			flex: 0 0 25%;
			max-width: 25%;
		}

		.inner{
			width: 100%;
		}

		.icon-con{
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			background-color: $green;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 17rem;
			margin: 0 auto 1.1rem;

			img{
				max-width: 92%;
				max-height: 54%;
			}
		}

		.text{

			p.large{
				@include line-height(20,25);
			}
		}
	}
}

.image-with-text-2-section{

	&.right{

		.text-side{

			@media (min-width: $md + 1){
				padding-left: 14.9rem;
				padding-right: 8rem;

				&:has(.link-con){

					p{

						&:last-of-type{
							padding-right: 28.8rem;
						}
					}
				}

				.link-con{

					.link{
						right: 4.9rem;
					}
				}
			}
		}

		.image-side{
	
			.over{
				right: -12.3rem;

				@media (max-width: $md){
					right: .75rem;
				}
			}
		}
	}

	&.left{

		.text-side{

			@media (min-width: $md + 1){
				padding-right: 14.9rem;
				padding-left: 8rem;

				&:has(.link-con){

					p{

						&:last-of-type{
							padding-left: 28.8rem;
						}
					}
				}

				.link-con{

					.link{
						left: 4.9rem;
					}
				}
			}
		}
	}

	.row{
		padding-top: 8.2rem;
		padding-bottom: 7.8rem;
		align-items: flex-start;

		@media (max-width: $md){
			padding-top: calc(10.4vw + 2rem);
			padding-bottom: 4rem;
		}
	}
	
	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 32.2%;
			max-width: 32.2%;
		}

		@media (max-width: $md){
			margin-bottom: calc(16.3vw + 2rem);
		}

		img{
			border-radius: 50% 0 50% 50%;
			position: relative;
			z-index: 1;
			width: 100%;
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
		}

		.over{
			position: absolute;
			top: -4.3rem;
			background-color: $red;
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: .5rem 1.5rem 2.5rem;
			width: 23.4rem;
			text-align: center;

			@media (max-width: $md){
				width: 18rem;
				top: auto;
				bottom: -2.3rem;
			}

			h2{
				@include line-height(31,39);
			}

			*{
				color: $blue-dark;

			}
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 67.8%;
			max-width: 67.8%;
			padding-top: 2.5rem;
		}

		h4{
			margin-bottom: .7em;

			@media (min-width: $md + 1){
				@include font-size(24);
			}
		}

		p{
			margin-bottom: .6em;

			&:last-child{
				margin-bottom: 0;
			}
		}

		.link-con{

			@media (min-width: $md + 1){
				position: relative;

				.link{
					position: absolute;
					bottom: -2.6rem;
					z-index: 2;
				}
			}

			.link{
				background-color: $red;
				aspect-ratio: 1/1;
				border-radius: 50% 0 50% 50%;
				@include font-size(19.42);
				@include line-height(19.42,23.83);
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				color: $blue-dark;
				width: 14rem;
				padding: .5rem 0.5rem 1.6rem;
	
				&:hover,
				&:focus-visible{
					background-color: darken($red, 7.5%);
				}
			}
		}
	}
}

.care-rating-section{

	.container{
		max-width: 106rem;
	}

	.row{
		padding-top: 5.6rem;
		padding-bottom: 5.7rem;
		align-items: center;
	}

	.col-md-4{

		@media (min-width: $md + 1){
			flex: 0 0 31.2%;
			max-width: 31.2%;
		}

		@media (max-width: $md){
			margin-bottom: 2rem;
		}

		img{
			width: 100%;
		}
	}

	.col-md-8{

		@media (min-width: $md + 1){
			flex: 0 0 68.8%;
			max-width: 68.8%;
		}

		a{
			color: $purple;

			&:hover,
			&:focus-visible{
				color: darken($purple, 15%);
			}
		}
	}

	h2{
		margin-bottom: .5em;
	}
}

.download-with-text-section,
.quote-section,
.link-with-text-section{

	.container{
		max-width: 106rem;
	}

	.row{
		padding-top: 3.4rem;
		padding-bottom: 5.5rem;
	}

	.quote-side,
	.link-side{

		@media (min-width: $md + 1){
			flex: 0 0 25%;
			max-width: 25%;
			margin-top: .9rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 75%;
			max-width: 75%;
			padding-left: 1.1rem;
			padding-right: 3.8rem;
		}

		p{

			&.large{

				strong{
					
					@media (min-width: $md + 1){
						@include font-size(25);
					}
				}
			}
		}
	}

	.link-con{
		position: relative;

		.shape{
			position: absolute;

			&.shape1{
				width: 53.44783%;
				bottom: -36.6%;
				left: 0%;
				z-index: 1;
			}

			&.shape2{
				width: 22.44348%;
				bottom: -40.6%;
				left: 39%;
				z-index: 2;
			}
		}

		.link{
			background-color: $red;
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			@include font-size(30);
			@include line-height(30,30);
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			flex-direction: column;
			color: $white;
			width: 23.5rem;
			padding: 1.7rem 2rem 4.5rem;

			@media (max-width: $md){
				width: 100%;
				margin-bottom: 42vw;
			}

			img{
				margin: .4rem auto 0;
				width: 91%;
			}

			&:hover,
			&:focus-visible{
				background-color: darken($red, 7.5%);
			}
		}
	}
}

.quote-section{
	position: relative;

	&::after{
		content: '';
		position: absolute;
		width: 108%;
		max-width: 108%;
		top: 0rem;
		right: -0%;	
		height: calc(100% + 29.6rem - 2.3rem);
		background-color: $red;
		z-index: -1;
		border-radius: 38% 0 0 0;

		@media (max-width: $md){
			height: calc(100% + 3rem - 2.3rem);
		}
	}

	.row{
		padding-top: 7.1rem;
		padding-bottom: 2.7rem;

		*{
			color: $white;
		}
	}

	.quote-side{
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;

		@media (min-width: $md + 1){
			margin-top: .3rem;
		}

		svg{
			width: 14.3rem;
			aspect-ratio: 143/97.82;
			
			@media (min-width: $md + 1){
				margin-right: -1rem;
			}
		}
	}
}

.download-with-text-section{
	background-color: $red;

	.row{
		padding-top: 2rem;
		padding-bottom: 4.6rem;
	}

	.link-side{
		position: relative;

		.shape{
			position: absolute;

			@media (max-width: $md){
				display: none;
			}

			&.shape1{
				width: 34.5%;
				top: -0%;
				right: 1.1rem;
				z-index: 1;
			}

			&.shape2{
				width: 23.5%;
				top: 13%;
				right: 3.7rem;
				z-index: 2;
			}
		}
	}

	*{
		color: $white;
	}

	.text-side.text-side.text-side.text-side.text-side{

		@media (min-width: $md + 1){
			padding-right: 19rem;
		}

		*{
			color: $white;

			a{
				color: $blue-dark;

				&:hover,
				&:focus-visible{
					color: darken($blue-dark, 15%);
				}
			}
		}

		h2{
			@include line-height(30,36);
			color: $colour;
			margin-bottom: .4em;

			*{
				color: $colour;
			}
		}

		p{

			&.large{
				margin-bottom: .65em;
			}
		}
	}

	h1{
		margin-bottom: .78em;
	}

	h4{
		margin-bottom: .7em;

		@media (min-width: $md + 1){
			@include font-size(24);
		}
	}

	.link-con.link-con.link-con.link-con.link-con.link-con.link-con{
		position: relative;

		.link{
			background-color: $yellow;
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			@include font-size(24.43);
			@include line-height(24.43,30.71);
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			color: $blue-dark;
			width: 14rem;
			padding: 1.5rem .5rem 2.1rem;

			&:hover,
			&:focus-visible{
				background-color: darken($yellow, 7.5%);
			}

			@media (max-width: $md){
				margin-bottom: 0;
				margin-top: 4rem;
			}
		
			@media (min-width: $md + 1){
				position: absolute;
				right: -17.5rem;
				bottom: 2rem;
			}
		}
	}
}

.services-hero-section + .images-with-text-section{

	@media (min-width: $md + 1){
		margin-top: -2rem;
	}

	.row{
		padding-top: 0;
	}
}

.images-with-text-section{
	position: relative;
	overflow: visible!important;

	&::after{
		content: '';
		position: absolute;
		width: 100%;
		max-width: 100%;
		bottom: 0;
		right: -0%;	
		min-height: 44.5rem;
		height: calc(100% + 18.8rem);
		background-color: $grey-light;
		z-index: -1;
		border-radius: 58rem 0 0 0;
	}

	~ section{
		background-color: $grey-light;

		&.related-section{
			background-color: transparent;

			@media (min-width: $md + 1){
				padding-bottom: 22.5rem;
			}

			~ section{ 
				background-color: transparent;
			}

			&::after{
				content: '';
				position: absolute;
				width: 100%;
				max-width: 100%;
				top: 0;
				right: -0%;	
				min-height: 44.5rem;
				height: calc(100% + -54.6rem);
				background-color: $grey-light;
				z-index: -1;
				border-radius: 0 0 50vw 50vw;
			}
		}
	}

	.container{
		max-width: 100rem;
	}

	.row{
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.title-con{
		text-align: center;

		h1{
			margin-bottom: 17.1rem;
			position: relative;
			color: $blue-dark!important;
	
			&::after{
				content: '';
				position: absolute;
				top: calc(100% + 1.6rem);
				height: 11rem;
				left: 50%;
				width: .1rem;
				transform: translateX(-50%);
				background-color: $red;
			}
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 57%;
			max-width: 57%;
			padding-right: 2.5rem;
		}

		p{

			&.large{
				margin-bottom: .75em;
			}
		}

		*:last-child{
			margin-bottom: 0;
		}
	}

	.image-side{

		@media (min-width: $md + 1){
			flex: 0 0 43%;
			max-width: 43%;
			margin-top: .4rem;
		}

		img{
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
			border-radius: 50% 0 50% 50%;

			&:nth-of-type(1){
				width: 60.1%;
				position: relative;
				z-index: 2;
			}

			&:nth-of-type(2){
				width: 100%;
				max-width: 100%;
				position: relative;
				z-index: 1;
				margin-left: 7.7%;
				margin-top: -24.2%;

				@media (min-width: $md + 1){
					width: 117.2%;
					max-width: 117.2%;
				}
			}
		}
	}
}

.text-blocks-section{

	.row{
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
		justify-content: center
	}

	.title-con{
		text-align: center;

		h1{
			margin-bottom: 14.8rem;
			position: relative;
			color: $blue-dark!important;
	
			&::after{
				content: '';
				position: absolute;
				top: calc(100% + 1.6rem);
				height: 11rem;
				left: 50%;
				width: .1rem;
				transform: translateX(-50%);
				background-color: $red;
			}
		}
	}

	.col-md-4{
		text-align: center;

		@media (max-width: $md){
			margin-bottom: 2rem;	
		}

		.inner{
			width: 100%;
		}

		.text{
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			background-color: $red;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 16.9rem;
			margin: 0 auto;
			padding: 2rem 2rem 2rem;

			img{
				max-width: 92%;
				max-height: 54%;
			}
	
			p.large{
				@include line-height(19.94,24.93);

				@media (min-width: $md + 1){
					@include font-size(19.94);
				}
			}
		}
	}
}

.research-paper-section{

	&.right{

		.text-side{

			@media (min-width: $md + 1){
				padding-left: 3.5rem;
				padding-right: 8rem;

				// &:has(.link-con){

				// 	p{

				// 		&:last-of-type{
				// 			padding-right: 28.8rem;
				// 		}
				// 	}
				// }

				.link-con{

					.link{
						right: 4.9rem;
					}
				}
			}
		}

		.image-side{
	
			.over{
				right: -12.3rem;
			}
		}
	}

	.container{
		max-width: 106rem;
	}

	.row{
		padding-top: 4.5rem;
		padding-bottom: 6.8rem;
		align-items: flex-start;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 2rem;
		}
	}
	
	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 29.2%;
			max-width: 29.2%;
		}

		@media (max-width: $md){
			margin-bottom: calc(16.3vw + 2rem);
		}

		img{
			position: relative;
			z-index: 1;
			width: 100%;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 70.8%;
			max-width: 70.8%;
			padding-top: 2.8rem;
		}

		h4{
			margin-bottom: .7em;

			@media (min-width: $md + 1){
				@include font-size(24);
			}
		}

		p{
			margin-bottom: .6em;

			&:last-child{
				margin-bottom: 0;
			}
		}

		.link-con{
			margin-top: 5.7rem;

			@media (max-width: $md){
				margin-top: 2.7rem;
			}

			// @media (min-width: $md + 1){
			// 	position: relative;

			// 	.link{
			// 		position: absolute;
			// 		bottom: -2.6rem;
			// 		z-index: 2;
			// 	}
			// }

			.link{
				background-color: $red;
				aspect-ratio: 1/1;
				border-radius: 50% 0 50% 50%;
				@include font-size(26.22);
				@include line-height(26.22,32.97);
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				color: $blue-dark;
				width: 14rem;
				padding: 1.4rem 0.5rem 1.6rem;
	
				&:hover,
				&:focus-visible{
					background-color: darken($red, 7.5%);
				}

				@media (max-width: $md){
					@include font-size(24);
				}
			}
		}
	}
}

.research-papers-section{

	.row{
		padding-top: 0rem;
		padding-bottom: 5.8rem;
		align-items: flex-start;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 4rem;
		}
	}
	
	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 33.2%;
			max-width: 33.2%;
		}

		img{
			position: relative;
			z-index: 1;
			width: 100%;
			margin: 2rem auto 0;
			width: 30.1rem;
		}

		h2{
			@include line-height(35,37);
			margin-bottom: .4em;

			@media (min-width: $md + 1){
				@include font-size(35);
			}
		}

		p{

			@media (min-width: $md + 1){
				padding-right: 1rem;
			}

			&.large{
				margin-bottom: .3em;
			}
		}

		.inner{
			background-color: $red;
			border-radius: 5.9rem;
			padding: 3.3rem 2.3rem 0;

			@media (max-width: $md){
				margin-top: 4rem;
			}

			*{
				color: white;
			}
		}
	}

	.text-side{
		position: relative;
		padding-left: 8.1rem;

		.shape{
			position: absolute;

			&.shape1{
				width: 9.2rem;
				top: 4.5rem;
				left: -2.6rem;
				z-index: 1;
			}

			&.shape2{
				width: 6.1rem;
				top: 11.3rem;
				left: -2rem;
				z-index: 2;
			}
		}

		@media (min-width: $md + 1){
			flex: 0 0 66.8%;
			max-width: 66.8%;
			padding-top: 3.6rem;
		}

		h1{
		
			&.large{
				margin-bottom: .76em;
			}
		}

		h2{
			@include line-height(30,36);
			margin-bottom: .38em;
		}

		h4{
			margin-bottom: .7em;
			

			@media (min-width: $md + 1){
				@include font-size(24);
			}
		}

		.text-con.text-con.text-con.text-con.text-con{
			
			&:has(+ .text-con){
				margin-bottom: 6.6rem;

				@media (max-width: $md){
					margin-bottom: 3.6rem;
				}
			}
		}

		p{
			margin-bottom: .6em;

			&:last-child{
				margin-bottom: 0;
			}

			&:last-of-type{

				@media (min-width: $md + 1){
					padding-right: 27rem;
				}
			}

		}

		.link-con{
			margin-top: 5.7rem;

			@media (max-width: $md){
				margin-top: 2.7rem;
			}

			@media (min-width: $md + 1){
				position: relative;
				margin-top: 0;

				.link{
					position: absolute;
					bottom: 1.6rem;
					z-index: 2;
					right: 4.6rem;
				}
			}

			.link{
				background-color: $red;
				aspect-ratio: 1/1;
				border-radius: 50% 0 50% 50%;
				@include font-size(26.22);
				@include line-height(26.22,32.97);
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				color: $blue-dark;
				width: 14rem;
				padding: 1.4rem 0.5rem 1.6rem;
	
				&:hover,
				&:focus-visible{
					background-color: darken($red, 7.5%);
				}

				@media (max-width: $md){
					@include font-size(24);
				}
			}
		}
	}
}
















.projects-section{
	overflow: initial!important;
	position: relative;
	padding-bottom: 16.8rem;

	@media (max-width: $md){
		padding-bottom: 15.5rem;
	}

	&::after{
		content: '';
		position: absolute;
		width: 100%;
		max-width: 100%;
		top: -27.3rem;
		left: 50%;
		height: calc(100% + 29.6rem - 6.3rem);
		transform: translateX(-50%);
		background-color: $grey-light;
		z-index: -1;
		border-radius: 50vw 0 50vw 50vw;

		@media (max-width: $md){
			top: -3rem;
			height: calc(100% + 3rem - 2.3rem);
		}
	}

	.nav-side{
		padding-top: 2.8rem;

		@media (min-width: $md + 1){
			flex: 0 0 22.5%;
			max-width: 22.5%;
		}
	}

	.side-nav{
		position: sticky;
		top: 1.4rem;
		top: 2.8rem;

		&:after{
			content: '';
			position: absolute;
			left: 0;
			top: .5rem;
			bottom: .5rem;
			width: 1px;
			background-color: #bababa;
		}
		
		a{
			display: block;
			position: relative;
			text-decoration: none;
			display: inline-block;
			padding-left: 4.2rem;
			width: 100%;

			&:has(+ a){
				margin-bottom: 3rem;

				@media (max-width: $md){
					margin-bottom: 1.5rem;
				}
			}

			&:after{
				content: '';
				position: absolute;
				left: 0;
				top: .5rem;
				bottom: .5rem;
				width: .6rem;
			}

			span{
				@include font-size(25);
				@include line-height(25,30);
				color: $colour;
				opacity: .5;

				@media (max-width: $md){
					opacity: 1;
				}
			}

			&:hover{

				span{
					opacity: .75;
				}
			}
			
			@media (min-width: $md + 1){
				
				&.active{

					&:after{
						background-color: $red;
					}
				
					span{
						opacity: 1;
					}
				}
			}
		}
	}

	.content-side{

		@media (min-width: $md + 1){
			flex: 0 0 77.5%;
			max-width: 77.5%;
		}
	}

	.container-fluid{
		padding-top: 2.9rem;
		padding-bottom: 8.6rem;

		@media (min-width: $md + 1){
			padding-bottom: 17.6rem;
		}

		@media (max-width: $md){
			padding-bottom: 2.9rem;
		}

		&:last-of-type{
			padding-bottom: 0;
		}

		.row{
			align-items: flex-start;
			justify-content: center;
		}

		&#section2,
		&#section1{

			@media (min-width: $md + 1){
				
				.text-side{
					padding-right: .2rem;

					&.under{
						margin-top: 8.4rem;
						padding-left: 5.3rem;
						padding-right: 11rem;
					}
				}

				.image-side.under{
					margin-top: 5.4rem;
				}
			}
		}

		&#section2{

			@media (min-width: $md + 1){

				h2 ~ h2{
					margin-top: .7em;
					margin-bottom: .65em;
				}
				
				.image-side{
					padding-left: 1.2rem;
					
					img{
						margin-top: -15.3rem;
						width: 107.8%;
						max-width: 107.8%;
					}
				}
			}
		}
	}

	.links-f{
		margin-top: 1.9rem;

		.link{
			background-color: $red;
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			@include font-size(15);
			@include line-height(15,15);
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			color: $white;
			width: 9rem;
			padding: 1.5rem 0.5rem;

			&:hover,
			&:focus-visible{
				background-color: darken($red, 7.5%);
			}
		}

		+ *{
			margin-top: 1rem;
		}
	}

	.text-top{
		margin-bottom: 3.9rem;

		@media (min-width: $md + 1){
			padding-right: 13rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 53.2%;
			max-width: 53.2%;

			&.under{
				flex: 0 0 71.8%;
				max-width: 71.8%;
				align-self: center;
			}
		}

		h2{
			color: $orange;
			margin-bottom: .35em;
		}

		p{
			margin-bottom: .8em;
		}

		* + ul{
			margin-top: -.5em;
		}

		ul{

			*:last-child{
				margin-bottom: 0;
			}
	
			li{
				margin-bottom: .43em;
				padding-left: .65em;
				
	
				&:before{
					left: 0em;
					top: .7em;
					width: .25em;
					height: .25em;
				}
			}
		}

		.what{
			position: relative;
			padding: 3rem 0 3rem 14.3rem;
			margin-top: 2.4rem;
			margin-bottom: 4.6rem;

			@media (min-width: $md + 1){
				width: calc(100% + 15rem);
				max-width: calc(100% + 15rem);
			}

			p{
				margin-bottom: 0;
			}

			i{
				aspect-ratio: 1/1;
				position: absolute;
				width: 10.9rem;
				background-color: $green;
				border-radius: 50% 0 50% 50%;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}

			h2{
				color: currentColor;
				margin-bottom: .2em;
			}
		}
	}

	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 46.8%;
			max-width: 46.8%;
			margin-top: 1.1rem;

			&.under{
				flex: 0 0 28.2%;
				max-width: 28.2%;
			}
		}

		@media (max-width: $md){
		
			margin-top: calc(8vw + 1rem);
			margin-bottom: calc(4.3vw + 2rem);
		}

		.shape{
			position: absolute;

			&.shape1{
				width: 32.93086%;
				top: -8.4%;
				left: 29.6%;
				z-index: 1;
			}

			&.shape2{
				width: 33.16241%;
				left: 0.1%;
				bottom: -4.6%;
			}

			&.shape3{
				width: 15.08515%;
				left: -8.1%;
				bottom: -2.3%;
			}
		}

		img{
			width: 100%;
		}
	}

	.ic-item{
		flex: 0 0 100%;
		max-width: 100%;
		text-align: center;
		margin-bottom: 6.25rem;

		@media (min-width: $sm + 1){
			flex: 0 0 50%;
			max-width: 50%;
		}

		@media (min-width: $md + 1){
			flex: 0 0 30.3333%;
			max-width: 30.3333%;
			margin-top: 4.7rem;
			padding-right: 3.2rem;
			padding-left: 3.2rem;
		}

		.inner{
			width: 100%;
		}

		.icon-con{
			aspect-ratio: 1/1;
			border-radius: 50% 0 50% 50%;
			background-color: $green;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 17rem;
			margin: 0 auto 1.1rem;

			img{
				max-width: 92%;
				max-height: 54%;
			}
		}

		.text{

			p.large{
				@include line-height(20,25);
			}
		}
	}

	.quote-slide-con{
		margin-top: 2rem;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 14.5rem;

		@media (max-width: $md){
			padding-right: .75rem;
			padding-left: .75rem;
		}

		svg{
			width: 14.3rem;
			margin-left: 1.4rem;
			margin-right: 2.3rem;

			@media (min-width: $md + 1){
				margin-top: -1.6rem;
			}

			@media (max-width: $md){
				margin-bottom: 1rem;
			}
		}

		.quote-slide{
			width: calc(100% - 14.3rem - 3.6rem - 8.3rem);

			@media (max-width: $md){
				width: calc(100% + 1.5rem + 1.5rem);
			}
		}

		.swiper-pagination{
			bottom: -4.9rem;

			.swiper-pagination-bullet{
				background-color: $white;
				width: 1.5rem;
				height: 1.5rem;

				&:hover,
				&:focus-visible,
				&.swiper-pagination-bullet-active{
					background-color: $red;
				}
			}
		}
	}

	.info{
		width: 100%;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;

		@media (max-width: $md){
			padding-right: .75rem;
			padding-left: .75rem;
		}

		.img-con{
			width: 40%;
			margin-right: 1.5rem;

			@media (max-width: $md){
				width: 100%;
				margin-top: 4rem;
				margin-right: 0;
			}

			img{
				width: 100%;
				aspect-ratio: 1/1;
				object-fit: cover;
				object-position: 50% 50%;
				border-radius: 50% 0 50% 50%;
			}
		}

		.facebook{
			position: absolute;
			top: 1.8rem;
			right: 51.4%;

			@media (max-width: $md){
				position: relative;
				top: 0;
				right: auto;
				left: 0;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			.text{
				@include font-size(35);
				@include line-height(35,38);
				text-align: right;
				width: 40rem;
			
				@media (max-width: $md){
					padding-right: 8rem;
					@include font-size(26);
				}
			}

			a{
				position: absolute;
				text-decoration: none;
				top: -3.7rem;
				right: -15.8rem;
				
				@media (max-width: $md){
					top: 50%;
					transform: translateY(-50%);
					right: 0;
				}

				&:hover,
				&:focus-visible{
					color: $blue-dark;
					
					.shape{
						background-color: darken($yellow,7.5%);
					}
				}

				.shape{
					aspect-ratio: 1/1;
					object-fit: cover;
					object-position: 50% 50%;
					background-color: $yellow;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 14.9rem;
					transition: $time;

					@media (max-width: $md){
						width: 7rem;
					}

					i{
						@include font-size(90);

						@media (max-width: $md){
							@include font-size(50);
						}
					}
				}
			}
		}

		.download{
			position: absolute;
			bottom: 1.8rem;
			right: 57.5%;
			width: 26.6rem;

			@media (max-width: $md){
				position: relative;
				top: 0;
				right: auto;
				left: 0;
				width: 100%;
				margin-top: 4rem;
			}

			@media (max-width: $md){
				position: relative;
				top: 0;
				right: auto;
				left: 0;
			}

			.text{
				@include font-size(35);
				@include line-height(35,38);
				text-align: right;

				@media (max-width: $md){
					padding-right: 8rem;
					@include font-size(26);
				}
			}

			a{
				position: absolute;
				text-decoration: none;
				top: -12.6rem;
				right: -24.3rem;
				width: 25.4rem;
				transform: rotate(0deg);

				&:hover,
				&:focus-visible{
					transform: rotate(18deg);
				}

				@media (max-width: $md){
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					width: 7rem;
				}

				img{
					width: 100%;
				}
			}
		}
	}

	.get.get.get.get.get.get{
		margin-top: 15.5rem;
		width: 100%;
		margin-left: 4.3rem;

		@media (max-width: $md){
			margin-top: 4rem;
			margin-left: 0;
		}
		
		.inner{
			background-color: $yellow;
			border-radius: 5.9rem;
			position: relative;
			padding: 1.9rem 2.7rem 3.2rem 3.4rem;
			
			.shape{
				position: absolute;
	
				&.shape1{
					width: 11.8%;
					top: -1.5rem;
					right: 11.9%;
					z-index: 1;
				}
	
				&.shape2{
					width: 11.4%;
					right: 3.4%;
					bottom: -5rem;

					@media (max-width: $md){
						display: none;
					}
				}
	
				&.shape3{
					width: 4.5%;
					right: 11.7%;
					bottom: -6.4rem;

					@media (max-width: $md){
						display: none;
					}
				}
			}

			.text{
				position: relative;
				z-index: 4;
			}
			

			*{
				color: $blue-dark;
			}

			h1{
				margin-bottom: .35em;
				color: $blue-dark;

				@media (min-width: $md + 1){
					@include font-size(65);
				}
			}

			p{
				margin-bottom: 1.3rem;

				@media (min-width: $md + 1){
					padding-left: .8rem;
					padding-right: 19rem;
				}
			}

			h4{
				margin-bottom: 1.1rem;
			}

			a{

				&:hover,
				&:focus-visible{
					color: darken($pink, 7.5%);

					*{
						color: darken($pink, 7.5%);
					}
				}
			}
		}
	}
}


section{

	p,
	li{

		@media (max-width: $md){
			
			a{
				word-wrap: break-word;
			}
		}
	}
}


































//
//!!BLOCKS END
//

@media (min-width: $md + 1){
	
	.container{
		max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
	}

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
		padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
	}

	.row{
		margin-right: -$grid-gutter-widthWO / 2 / 10 + rem;
		margin-left: -$grid-gutter-widthWO / 2 / 10 + rem;
	}
}

@media (max-width: $md){

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: .75rem;
		padding-left: .75rem;
	}

	.container{
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.row{
		margin-right: -.75rem;
		margin-left: -.75rem;
	}
}