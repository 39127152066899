
//!!FORM
::-webkit-input-placeholder {
	@include font-size(25);
	color: $colour;

	@media (max-width: $md){
		@include font-size(14);
	}
}
 
:-moz-placeholder { /* Firefox 18- */
	@include font-size(25);
	color: $colour;

	@media (max-width: $md){
		@include font-size(14);
	}
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(25);
	color: $colour;

	@media (max-width: $md){
		@include font-size(14);
	}
}

:-ms-input-placeholder {
	@include font-size(25);
	color: $colour;

	@media (max-width: $md){
		@include font-size(14);
	}
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 1.2rem;
$label-bottom-margin: 0rem;

input,
input.input-text,
textarea,
.fake-select,
select{
	display: block;
	padding: .4rem 1.9rem;
	@include font-size(25);
	@include line-height(25,25);
	font-weight: 300;
	color: $colour;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: 0;
	border: .1rem solid $grey;
	background-color: $grey;
	border-radius: 1.5rem;
	-webkit-appearance: none;
	box-shadow: none;

	@media (max-width: $md){
		padding: 1.2rem 1.9rem;
		@include font-size(16);
	}
}

label + .wpcf7-form-control-wrap:has(textarea){
	margin-top: 1.6rem;
}

textarea{
	height: 20rem;
	min-height: 20rem;
	min-width: 100%;
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

//
//!!SELECT START
//
.fake-select,
select{
	padding-right: 4rem;
	appearance: none;
	background-image: url(../images/select-icon.svg);
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: 1.6rem auto;
	line-height: 5.6rem - .1rem - .1rem;
	height: 5.6rem;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
	background-position: right 1.5rem top 50%;

	@media (max-width: $md){
		line-height: 5.533rem - .1rem - .1rem;
		height: 5.533rem;
	}

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: .05rem;
		padding-bottom: .05rem;
		color: $black;
	}
}
//
//!!SELECT END
//

legend,
label{
	display: block;
	@include font-size(25);
	@include line-height(25,27);
	font-family: $main-font;
	margin-bottom: $label-bottom-margin;
	transition: $time;
	font-weight: 300;
	color: $colour;
	width: 100%;
	padding-left: 1.9rem;

	@media (max-width: $md){
		@include font-size(16);
	}

	.gfield_required{
		
		&::before{
			content: ' *';
			color: inherit;
			margin-left: .2em;
			display: inline-block;
		}
		
		span{
			display: none;
		}
	}
}

*[type="submit"],
.submit-con [type="submit"]{
	@extend %btn;
	background-color: transparent;
	font-size: 1.95rem!important;
	display: flex;
	align-items: center;
	padding: 0;
	border-radius: 0;
	border: none;
	color: $blue-dark!important;
	margin-left: auto;

	&:hover,
	&:focus-visible{
		background-color: transparent;
		color: $blue-dark!important;
		
		&::before{
			background-color: darken($red, 7.5%);
		}
	}

	&::before{
		content: '';
		width: 2.5rem;
		aspect-ratio: 1/1;
		border-radius: 50% 0 50% 50%;
		background-color: $red;
		margin-right: .8rem;
	}
}

.submit-con{
	display: flex;
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	& + span,
	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 3.2rem!important;
		position: relative;
		cursor: pointer;
		@include font-size(25);
		line-height: 2.4rem;
		@include inline;
		margin-top: 0;
		font-style: normal;
		color: $colour;
		font-weight: 300;
		text-transform: none;
		text-align: left!important;
		width: 100%;
		margin-bottom: 1.45rem;

		a{
			font-weight: 700;

			&:hover,
			&:focus-visible{
				text-decoration: underline;
			}
		}

		&:before{
			content: '';
			position: absolute;
			width: 2.4rem;
			height: 2.4rem;
			left: 0rem;
			top: 0rem;
			border-radius: .6rem;
			border: .2rem solid #98A2B3;
			background-color: white;
		}

		&:after{
			content: '';
			position: absolute;
			width: 2.4rem;
			height: 2.4rem;
			left: 0rem;
			top: 0rem;
			border-radius: .6rem;
			border: .2rem solid $green;
			background-color: $black;
			opacity: 0;
			transition: $time;
			background-color: $green;
			background-image: url(../images/check.svg);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: 1.6rem auto;
		}
	}

	&:checked + label,
	&:checked + .wpcf7-list-item-label,
	&:checked + span,
	& + label.checked{
		color: $green;
	}

	&:checked + label:after,
	&:checked + .wpcf7-list-item-label:after,
	&:checked + span:after,
	& + label.checked:after{
		opacity: 1;
	}
}

input[type="radio"]{

	& + span,
	& + .wpcf7-list-item-label,
	& + label{

		&:before,
		&:after{
			border-radius: 50%;
		}

		&:after{
			border: .4rem solid $green;
			background-color: $black;
			background-image: none;
		}
	}
}

//
//!!RADIO & CHECKBOX END
//


//
//!!GRAVITY START
//

.gform_wrapper{
	position: relative;

	.gform_submission_error{
		display: none;
	}

	fieldset{
		padding: 0;
		margin: 0;
		border: none;
	}

	.gform_fields{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1px 0 1px;
	
		.gfield,
		.gfield--width-half,
		.gfield--width-third{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			margin-bottom: $input-bottom-margin;

			@media (max-width: $lg){
				margin-bottom: 3.3rem;
			}

			@media (min-width: $md + 1){
				align-items: center;
			}

			@media (max-width: $md){
				margin-bottom: 3.05rem;
			}
		}

		.gfield{
			width: 100%;

			&.spacer{
				margin-bottom: 0;
			}
		}

		.gfield--width-half{
			width: calc(50% - (#{$grid-gutter-widthWO/10+rem}/2));

			@media (min-width: $md + 1){
				width: calc(50% - 1rem);
			}	

			@media (max-width: $md){
				width: 100%;
			}


		}

		.gfield--width-third{
			width: 32%;
			width: calc(33.333% - (#{$grid-gutter-widthWO/10+rem}/2));

			@media (min-width: $md + 1){
				width: calc(33.333% - 1rem);
			}

			@media (max-width: $md){
				width: calc(50% - (#{$grid-gutter-widthWO/10+rem}/2));
				width: calc(50% - 1rem);

				+ .small + .small{
					width: 100%;
				}
			}

			@media (max-width: $sm){
				width: 100%;
			}
		}

		.gfield{

			&.hidden_label{
				
				legend,
				label{
					display: none;
				}
			}

			&.gfield--type-select{
				margin-bottom: 2.7rem;
			}

			.ginput_container{
				width: 100%;
			}

			&.gfield--type-consent{
				border: none;
				padding: 0;
				margin-left: 0;
				margin-right: 0;

				.gfield_label{
					display: none;
				}
			}
		}

		fieldset.gfield{
			position: relative;

			.gfield_label{
				position: absolute;
				top: 0;
				left: 0;

				@media (max-width: $lg){
					position: static;
				}
			}
		}
	}

	.gfield--type-radio,
	.gfield--type-checkbox{

		&.gfield_error{
			
			
			input[type="radio"],
			input[type="checkbox"]{

				& + span,
				& + .wpcf7-list-item-label,
				& + label{
		
					&:before{
						border-color: $red;
						border-width: .4rem;
					}
				}
			}
		}
	}

	.gform_fileupload_rules{
		display: none;
	}

	.gform_footer{
		margin-top: 1.4rem;
	}

	.ginput_preview_list{
		@include font-size(14);
		font-family: $main-font;
		position: absolute;
		top: 100%;
		width: 100%;

		.gfield_fileupload_percent{
			margin-left: .4em;
		}

		.gform_delete_file{
			text-decoration: none;
			@include font-size(18);
			margin-left: 0em;
			line-height: 1.1;

			*{
				@include font-size(18);
			}
		}
	}

	.gfield_validation_message{
		@include font-size(14);
		color: $red;
		font-family: $main-font;
		padding-left: 1.9rem;
	}

	.gform_ajax_spinner{
		margin: 1rem auto 0;
	}

	.gform_submission_error{
		@include font-size(25);
		color: $red;
		color: $white;
		font-weight: 300;
		font-family: $main-font;

		@media (max-width: $md){
			@include font-size(16);
		}
	}
}

.gform_confirmation_message{
	font-weight: 300;
	@include font-size(18);
	font-family: $main-font;
	color: $green;
	text-align: center;
}

//
//!!GRAVITY END
//

#ui-datepicker-div{
	background-color: $main-bg-colour;
	border: .1rem solid $colour;
	width: 32rem;
	z-index: 6!important;

	.ui-datepicker-header{
		padding: 1rem 1rem;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.ui-datepicker-prev,
		.ui-datepicker-next{
			padding: .5rem 1rem;
			background-color: $black;
			color: $white;
			text-decoration: none;
			@include font-size(14);
			letter-spacing: .2em;
			font-weight: 500;
			font-family: $alt-font;
			text-transform: uppercase;
			margin-bottom: 1rem;

			&:hover,
			&:focus-visible{
				background-color: $colour;
			}
		}

		.ui-datepicker-title{
			width: 100%;
		}

		.ui-datepicker-month{
			margin-bottom: 1rem;
		}
	}

	.ui-datepicker-calendar{
		width: 100%;
		text-align: center;
		margin-bottom: 0;

		thead{

			tr{
				background-color: lighten($colour, 10%);

				th{
					padding: 1.1595rem .5rem;
					border-top: .1rem solid $colour;
					border-right: .1rem solid $colour;
					width: calc(100% / 7)!important;
					font-family: $alt-font;
					@include font-size(25);

					@media (max-width: $md){
						@include font-size(18);
					}

					&:last-of-type{
						border-right: none;
					}
				}
			}
		}

		tbody{

			tr{
				background-color: $white;

				td{
					padding: 0rem .5rem;
					border-top: .1rem solid $colour;
					border-right: .1rem solid $colour;
					width: calc(100% / 7)!important;
					height: 4.552rem;
					line-height: 4.552rem;
					font-family: $alt-font;
					position: relative;
					@include font-size(14);
					font-weight: 300;

					a{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						text-decoration: none;

						&.ui-state-hover.ui-state-active,
						&.ui-state-active{
							background-color: $colour;
							color: $white;
						}

						&.ui-state-hover{
							background-color: darken($white, 5%);
						}
					}

					&:last-of-type{
						border-right: none;
					}
				}
			}
		}
	}
}
