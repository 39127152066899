.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 1.1rem;
	overflow: hidden;
	position: relative;

	&::before{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 1.5rem;
		height: 100%;
		background-color: $white;
		z-index: 2;
	}

	.prev,
	.pagination-mid{
		position: relative;

		&::before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: calc(100% + 4.2rem);
			width: 100vw;
			max-width: 100vw;
			height: 1px;
			background-color: #bfbfbf;
		}
	} 

	.prev + .pagination-mid{

		&::before{
			display: none;
		}
	}

	.post-type-archive-campaigns &{

		@media (max-width: $md){
			margin-top: -.7rem;
		}
	}

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		width: auto;
		height: 3.2rem;
		@include font-size(20);
		line-height: 3.2rem;
		position: relative;
		z-index: 1;

		@media (max-width: $md){
			height: 2.4rem;
			line-height: 2.4rem;
		}


		&:not(.prev):not(.next){
			margin: 0 .35em;

			@media (max-width: $md){
				margin: 0 .15em;
			}

			&.active{
				pointer-events: none;

				a{
					color: $white;
					text-decoration: none;
					font-weight: 600;
				}
			}

			span,
			a{
				@include font-size(18);
				line-height: 2.9rem;
				display: block;
				color: $white;
				font-weight: 500;
				padding: 0;
				text-decoration: none;
				width: 3.2rem;
				height: 3.2rem;
				border-radius: 0;
				text-align: center;
				font-family: $main-font;
				background-color: $red;
				border-radius: 50% 0 50% 50%;

				@media (max-width: $md){
					@include font-size(14);
					width: 2.4rem;
					height: 2.4rem;
					line-height: 2.3rem;
				}

				&:hover,
				&:focus-visible{
					background-color: darken($red, 7.5%);
				}
			}

			&.ellipse{
				color: $blue-dark;
			}
		}

		&.next,
		&.prev{

			span,
			a{
				@include font-size(18);
				width: 3.2rem;
				height: 3.2rem;
				display: flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;
				background-color: $red;
				border-radius: 50% 0 50% 50%;

				@media (max-width: $md){
					width: 2.4rem;
					height: 2.4rem;
				}

				svg{
					width: .8rem;

					@media (max-width: $md){
						width: .6rem;
					}

					path{
						fill: $white;
					}
				}

				&:hover,
				&:focus-visible{
					background-color: darken($red, 7.5%);
				}
			}

			span{
				pointer-events: none;
			}
		}

		&.next{
			margin-left: .35em;

			@media (max-width: $md){
				margin-left: .15em;
			}
		}

		&.prev{
			margin-right: .35em;

			@media (max-width: $md){
				margin-right: .15em;
			}
		}
	}


}