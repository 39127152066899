//!!SCALE SETTINGS
$scale: true; //false, true
$vw-viewport: 1280; //If false does nothing. if true set this to the width of the design sent over

//!!GRID SETTINGS
$grid-gutter-widthWO: 30 !default; //most common gutter width

//!!PAGE WIDTH
$base-widthWO:		1170; //most common container width

//!!COLOURS
$red:				#cd1f43;

$green:				#bed12f;
$green-dark:		#74b743;

$pink:				#e7067f;

$blue-light:		#dce1ec;
$blue:				#0695cb;
$blue-dark:			#13377e;
$blue-darker:		#2d4355;

$teal:				#0eb4ad;

$orange:			#e84e14;

$yellow:			#fad644;
$yellow:			#ffc907;

$purple:			#6a2b82;

$grey-light:		#f4f4f4;
$grey:				#e9e9e9;
$grey-dark:			#bebdb8;

$white:				#FFFFFF;
$black:				#1E1E1E;

$colour: 			$blue-dark;
$main-bg-colour:	$white;

//!!FONTS

@font-face {
	font-family: 'Lexend';
	src: url('../fonts/Lexend-Light.woff2') format('woff2'),
		url('../fonts/Lexend-Light.woff') format('woff'),
		url('../fonts/Lexend-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lexend';
	src: url('../fonts/Lexend-SemiBold.woff2') format('woff2'),
		url('../fonts/Lexend-SemiBold.woff') format('woff'),
		url('../fonts/Lexend-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

$font-lexend:		'Lexend', sans-serif;
//light - 300
//semi - 600

$main-font:			$font-lexend;
$alt-font:			$font-lexend;

//!!SITE TRANSITIONS
$time:				.3s;

//BREAK POINTS
$xl:				$base-widthWO - 1px ; //$base-width + - 1px
$lg:				992px; //992px - 1px
$md:				769px - 1px; //769px - 1px
$sm:				576px - 1px; //576px - 1px

//xl: $base-width
//lg: 992px
//md: 768px
//sm: 576px