//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -1.3rem;
	max-width: calc(100% + 1.3rem + 1.3rem);
	flex-wrap: wrap;

	@media (max-width: $md){
		margin: 0 -1rem;
		max-width: calc(100% + 1rem + 1rem);
	}

	.arrow-link,
	.btn{
		margin: 0 1.3rem 1rem;

		@media (max-width: $md){
			margin: 0 1rem 1rem;
		}

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1.8rem!important;
	line-height: 1.2;
	font-weight: 700;
	color: $black!important;
	text-decoration: none!important;
	font-family: $main-font;
	text-decoration: none;
	text-align: center;
	padding: 1.912rem 3.1rem;
	background-color: $green;
	border: .2rem solid $green;
	border-radius: 3rem;
	box-shadow: none;
	cursor: pointer;
	position: relative;
	transition: $time;
	z-index: 2;

	@media (max-width: $md){
		font-size: 1.4rem!important;
		padding: 1.412rem 2.2rem;
	}
	
	&:hover,
	&:focus-visible{
		outline: none;
		background-color: $black;
		border-color: $black;
		color: $white!important;
	}

	&.disabled,
	&:disabled{
		opacity: .25;
		pointer-events: none;
	}
}

.btn{

	&.dark{
		background-color: $black;
		border-color: $black;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: $white;
			border-color: $white;
			color: $black!important;
		}
	}

	&.red{
		background-color: $red;
		border-color: $red;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: darken($red, 7.5%);
			border-color: darken($red, 7.5%);
			color: $white!important;
		}
	}

	&.big{
		font-size: 2.0rem!important;
		padding: 2.234rem 1.8rem;
		min-width: 37.922rem;
	}
 
	&.small{
		font-size: 1.56rem!important;
		padding: 0rem 1.7rem;
		font-weight: 300;
	}
}