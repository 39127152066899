footer{
	position: relative;

	.container{
		max-width: 100rem;

		// @media (min-width: 1536px + 1){
		// 	max-width: calc(100% - 28rem);
		// }
	}

	.footer-top-outer{
		background-color: $blue-dark;

		*{
			color: $white;
		}

		.row{
			align-items: center;
		}

		.container{
			padding-top: 2.9rem;
			padding-bottom: 2.9rem;

			@media (max-width: $md){
				text-align: center;
			}
		}

		.col-md-8{
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			@media (max-width: $md){
				flex-direction: column;
			}

			a{
				display: flex;
				align-items: center;
				@include font-size(19.5);
				font-weight: 600;
				text-decoration: none;
				margin-right: 4.8rem;

				@media (max-width: $md){
					margin-right: 0;
					margin-bottom: 2rem;
					text-align: center;
					justify-content: center;
				}

				&:hover,
				&:focus-visible{
					
					&::before{
						background-color: darken($red, 7.5%);
					}
				}

				&::before{
					content: '';
					width: 2.5rem;
					aspect-ratio: 1/1;
					border-radius: 50% 0 50% 50%;
					background-color: $red;
					margin-right: .8rem;
				}
			}
		}

		.col-md-4{

			.social-menu{
				font-size: 2.9rem;
				line-height: 2.9rem;
				height: 2.9rem;

				@media (max-width: $md){
					justify-content: center;
				}

				a{
					margin: 0 1.4rem 0 0;

					&:last-of-type{
						margin-right: 0;
					}

					i{
						font-size: 2.9rem;
						line-height: 2.9rem;
						height: 2.9rem;
					}
				}
			}
		}
	}

	.footer-bottom-outer{
		background-color: $blue-dark;

		*{
			color: $white;
		}

		.row{
			text-align: center;
			align-items: center;
		}

		.container{
			padding-top: 3.9rem;
			padding-bottom: 1.2rem;

			@media (max-width: $md){
				text-align: center;
			}
		}

		.footer-menu{
			@include font-size(12);
			margin: 0 0 .1rem;
			margin: 0 0 .3rem;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			align-items: center;

			li{
				@include font-size(12);
				margin-bottom: 1.1rem;
				
				&:has(+ li){
					margin-right: 1.1rem;
					position: relative;

					&:after{
						content: '';
						position: absolute;
						top: 55%;
						right: -.55rem;
						left: auto;
						transform: translate(50%, -50%);
						width: .2em;
						height: .2em;
						border-radius: .3em;
						background-color: $red;
						display: block;
					}

				}
			}

			a{
				@include font-size(12);
				font-family: $main-font;
				font-weight: 300;
				text-decoration: none;

				&:hover,
				&:focus-visible{
					color: darken($red, 7.5%);
				}
			}
		}

		.copy{
			@include font-size(12);
			line-height: 1.2;
			margin-bottom: 1.1em;
		}
	}
}