//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: relative;
	top: 0;
	left: 0;
	transition: $time;
	
	// .scroll-down &,
	// .scroll-up &{

	// }

	@media (max-width: $md){
		
		.pop-open &{
			z-index: 0;
			transition: 0s;
		}
	}
}


#site-logo{
	display: block;
	height: auto;
	margin: 0;
	z-index: 2;
	transition: 0s;
	display: flex;
	width: 17.1rem;

	@media (max-width: $md){
		width: 7.5rem;
		z-index: 3;
		position: relative;
	}

	img,
	svg{
		width: 100%;
	}
}

.menu-top-outer{
	transition: $time;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	background-color: $grey-light;

	@media (max-width: $md){
		display: none;
	}

	// @media (min-width: 1536px + 1){
		
	// 	.container{
	// 		max-width: calc(100% - 8rem);
	// 	}
	// }
	
	.col-12{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 2.3rem;
		padding-bottom: 2.3rem;

		@media (max-width: $md){
			padding-top: 2.4rem;
			padding-bottom: 2.4rem;
		}

		.left,
		.right{
			display: flex;
			align-items: center;
			min-width: 10rem;
		}

		.left{
			align-content: flex-start;

			@media (max-width: $md){
				min-width: 8.8rem;
			}
		}

		.right{
			justify-content: flex-end;
		}
	}

	.link{
		background-color: $red;
		@include font-size(15.6);
		line-height: 1;
		font-weight: 600;
		border-radius: 1.2rem;
		color: white;
		text-decoration: none;
		display: inline-block;
		padding: .32rem 1rem .52rem;
		text-align: center;
		min-width: 11.2rem;

		&:hover,
		&:focus-visible{
			background-color: darken($red, 7.5%);
		}
	}

	.search-form{
		margin: 0;
		width: 16.4rem;

		::-webkit-input-placeholder {
			@include font-size(15.6);
		}
		 
		:-moz-placeholder { /* Firefox 18- */
			@include font-size(15.6);
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			@include font-size(15.6);
		}
		
		:-ms-input-placeholder {
			@include font-size(15.6);
		}

		input{
			border-color: $blue-dark;
			color: $colour;
			border-radius: 1.2rem;
			padding: .1rem 1.2rem;
			@include font-size(15.6);
			background-color: $white;
		}
	}

	.link,
	.search-form,
	.social-menu{
		margin-left: 1rem;
	}
}

.menu-bottom-outer{
	transition: $time;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	// @media (min-width: 1536px + 1){
		
	// 	.container{
	// 		max-width: calc(100% - 8rem);
	// 	}
	// }

	@media (max-width: $md){
		background-color: $grey-light;
	}
	
	.col-12{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 1.6rem;
		padding-bottom: .2rem;

		@media (max-width: $md){
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;
		}
	}

	.link{
		background-color: $red;
		@include font-size(15.6);
		line-height: 1;
		font-weight: 600;
		border-radius: 1.2rem;
		color: white;
		text-decoration: none;
		display: inline-block;
		padding: .32rem 1rem .52rem;
		text-align: center;
		min-width: 11.2rem;
		margin-bottom: 2rem;
		width: 100%;

		@media (min-width: $md + 1){
			display: none;
		}

		&:hover,
		&:focus-visible{
			background-color: darken($red, 7.5%);
		}
	}

	.search-form{
		margin: 0;
		width: 100%;

		@media (min-width: $md + 1){
			display: none;
		}

		::-webkit-input-placeholder {
			@include font-size(15.6);
		}
		 
		:-moz-placeholder { /* Firefox 18- */
			@include font-size(15.6);
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			@include font-size(15.6);
		}
		
		:-ms-input-placeholder {
			@include font-size(15.6);
		}

		input{
			border-color: $blue-dark;
			color: $colour;
			border-radius: 1.2rem;
			padding: .1rem 1.2rem;
			@include font-size(15.6);
			background-color: $white;
		}
	}

	.social-menu{
		margin-top: 2rem;
		margin-bottom: 2rem;

		@media (min-width: $md + 1){
			display: none;
		}
	}
}

.mob-only.mob-only{
	width: 100%;

	@media (min-width: $md + 1){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media (max-width: $md){
		margin: 0 auto;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		transition: $time;
		padding: 7.19rem 1.6rem 0;
		flex-direction: column;
		flex-wrap: nowrap;
		display: flex;
		overflow: hidden;
		z-index: 1;
		height: 100vh;
		max-height: 100vh;
		overflow: auto;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		background-color: $grey-light;
		

		&:has(li.open){
			overflow: hidden;
		}

		.menu-open &{
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
		}
	}	
}

@media (min-width: $md + 1){


	#mainMenu .main-menu .sub-menu .current-menu-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .sub-menu .current-page-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .sub-menu .current-menu-parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .sub-menu .current_page_parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .sub-menu .current-menu-item:not(.menu-item-object-custom){

		> a{
			color: $blue-dark;
			font-weight: 600;
		}
	}

	#mainMenu .main-menu .current-menu-ancestor,
	#mainMenu .main-menu .current-page-ancestor,
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent,
	#mainMenu .main-menu .current-menu-item{

		> a{
			color: $red;
			font-weight: 600;
		}
	}
}

ul.main-menu.main-menu{
	margin: 0 0 1.2rem;
	padding: 0;
	@include font-size(0);
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media (max-width: $md){
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		text-align: left;
		align-items: stretch;
		flex-wrap: nowrap;
		padding: 0;
		border-radius: 2.166rem;
		overflow: auto;
		max-height: calc(100dvh - 7.19rem - 2rem);
		margin-bottom: 2rem;
	}
	
	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		display: flex;
		text-align: left;
		
		@media (min-width: $md + 1){
			margin: 0 0 0 3.1rem;

			&:hover,
			&:focus-visible{
				
				> a{
					color: $red;
				}

				&.menu-item-has-children{

					> a{
	
						&:after{
							transform: translateY(-50%) rotateX(180deg);
						}
					}
				}
			}

			img{
				margin: 0 auto 1rem;
			}

			&.mob-back{
				display: none;
			}
		}

		@media (max-width: $md){
			
			&.open{

				.sub-menu.sub-menu{
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
					max-height: 9999px;
					margin-bottom: 2rem;
				}
			}
		}

		@media (max-width: $md){
			align-items: flex-start;
			padding-bottom: 0rem;
			margin-bottom: 0rem;

			img{
				display: none;
			}
		}
		
		// &:last-of-type{
		// 	margin-right: 0;
		// }

		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		&.menu-item-has-children{
			flex-wrap: wrap;
			position: relative;

			@media (max-width: $md){
				padding-right: 4.3rem;
				position: relative;

				&:after{
					content: '';
					position: absolute;
					width: 1rem;
					aspect-ratio: 16 / 10;
					right: 0;
					top: 1.6rem;
					transform: rotateX(0deg);
					background-image: url(../images/menu-arrow.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					transition: $time;
					backface-visibility: visible!important;
					display: block!important;
				}

				&.open{

					&:after{
						transform: rotateX(180deg);
					}
				}
			}

			.sub-menu{
				position: absolute;
				top: 100%;
				left: -.9rem;
				width: 18.3rem;
				max-width: 18.3rem;
				pointer-events: none;
				opacity: 0;
				visibility: hidden;
				border-radius: 2.3rem;
				background-color: $red;
			
				@media (min-width: $md + 1){
					padding: 1.3rem 1.5rem 1.3rem;;
				}

				@media (max-width: $md){
					position: static;
					transform: none;
					max-height: 0;
					overflow: hidden;
					width: 100%;
					max-width: 100%;
					background-color: transparent;
					border-radius: 0;
				}

				li{
					width: 100%;
					text-align: left;
					margin: 0;

					@media (max-width: $md){
						width: 100%;
						text-align: left;
					}

					@media (min-width: $md + 1){
						
						&:hover,
						&:focus-visible{

							> a{
								color: $blue-dark;
							}
							
						}
					}
				}

				a{
					@include font-size(16);
					line-height: 1.2;
					font-weight: 600;
					color: $white;
					padding: .25rem 0;
					width: 100%;
					margin: 0 auto;

					@media (min-width: $md + 1){
						
						&:hover,
						&:focus-visible{
							color: $blue-dark;
						}
					}

					@media (max-width: $md){
						color: $blue-dark;
					}

					&:after{
						display: none;
					}
				}
			}

			@media (min-width: $md + 1){
				
				&:hover,
				&:focus-visible{
					
					.sub-menu{
						pointer-events: auto;
						opacity: 1;
						visibility: visible;
						transition: $time;
					}
				}
			}
		}

		[href="#"]{
			pointer-events: none;
		}

		> a{
			@include font-size(22);
			line-height: 1.5;
			width: auto;
			color: $colour;
			text-decoration: none!important;
			font-weight: 300;
			position: relative;
			font-family: $main-font;
			padding: .5rem 0;
			z-index: 2;

			@media (max-width: $md){
				padding: .45rem 0;
			}

			@media (min-width: $md + 1){
				
				&:hover,
				&:focus-visible{
					color: $red;
				}
			}
		}
	}
}

@media (min-width: $md + 1){
	
	body.single{

		#mainMenu .main-menu #menu-item-13{

			> a{
				font-weight: 300;
				color: $colour;

				&:hover,
				&:focus-visible{
					color: $red;
				}
			}
		}
	}
}